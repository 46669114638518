function downloadToFile(content, filename, contentType) {
  const a = document.createElement("a");
  const file = new Blob([content], { type: contentType });

  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();

  URL.revokeObjectURL(a.href);
}

function convertPhoneNumber(inputString) {
  if (inputString.size < 10) return ""
  const countryCode = inputString.substring(0, 3);
  const phoneNumber = inputString.substring(4);
  return `TEL;type=Mobile;waid=${countryCode.substring(1,3)}${phoneNumber}:${countryCode} ${phoneNumber.substring(0, 5)} ${phoneNumber.substring(5)}`;
}

const makeVCardVersion = () => `VERSION:3.0`;
const makeVCardInfo = (name) => `N:;${name};;;`;
const makeVCardName = (name) => `FN:${name}`;
const makeVCardOrg = (org) => `ORG:${org}`;
const makeVCardTel = (phone1) => convertPhoneNumber(phone1);
const makeVCardEmail = (email) => `EMAIL;TYPE=Work:${email}`;


export const makeVCard = (fullName, companyName, phoneNumber, email) => {
  let vcard = `BEGIN:VCARD
${makeVCardVersion()}
${makeVCardInfo(fullName)}
${makeVCardName(fullName)}
${makeVCardOrg(companyName)}
${makeVCardTel(phoneNumber)}
${makeVCardEmail(email)}
END:VCARD`;
    downloadToFile(vcard, `${fullName}.vcf`, 'text/vcard');
};
