import { makeStyles } from "@mui/styles";


export const useStyles = makeStyles((theme) => ({
    contentBox: {
        marginBottom: 20,
        position: 'relative',
        width: '90%',
        marginTop: 20
    },
    contentHeading: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(18),
        color: theme.palette.primary.black
    },
    stepFlex:{
        overflowX: 'auto',
        paddingBottom: 17,
        display: 'flex',
        flexWrap: 'nowrap',
        marginTop: 40,
        scrollBehavior: 'smooth'
    },
    step: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: 20,
        textAlign: 'center'
    },
    stepImage: {
        marginBottom: 25
    },
    stepIconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    arrowFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        width: '100%'
    },
    arrowIconLeft: {
        transform: 'scale(1.5)',
        margin: 10,
        position: 'absolute',
        left: -40,
        top: -170,
        opacity: 0.5
    },
    arrowIconRight: {
        transform: 'scale(1.5)',
        margin: 10,
        position: 'absolute',
        top: -170,
        right: -40,
        opacity: 0.5

    }
}))