import React from "react";
import { useStyles } from "./FooterStyle";
import { Box, IconButton, Link, Typography } from "@mui/material";
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import CallIcon from '@mui/icons-material/Call';
import CompanyLogo from '../../../assets/Logo_Synclink.svg';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function Footer() {
  const classes = useStyles();

  return (
    <Box className={classes.footer}>
       <Box className={classes.companyLogo}>
            <img src={CompanyLogo} />
            </Box>
      <Box className={classes.contactUs}>Contact</Box>
      <Box className={classes.contactUsIconBox}>
        <IconButton href="mailto:support@synclink.in"><MailOutlinedIcon className={classes.icon}/><Typography className={classes.contactUsText}>support@synclink.in</Typography></IconButton>
        <IconButton href="https://wa.me/919257430479"><WhatsAppIcon className={classes.icon}/><Typography className={classes.contactUsText}>Chat with us on +91-9257430479</Typography></IconButton>
      </Box>
      <Box className={classes.copyright} display='flex' alignItems='flex-start'>
      <Link style={{color: 'white', cursor: 'pointer'}} href="/privacy-policy">Privacy Policy</Link>
      <Box style={{marginLeft: 10, marginRight: 10}}>|</Box>
      <Link style={{color: 'white', cursor: 'pointer'}} href="/terms-and-conditions">Terms & Conditions</Link>
      </Box>
    <Box className={classes.copyright}>Copyright &#169; 2024</Box>
    </Box>
  );
}

export default Footer;
