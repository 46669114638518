import { Box, Container, IconButton, Paper } from "@mui/material";
import React from "react";
import { useStyles } from "./Style";
import { handleOpenLink } from "../../../../utils/helper";
function ServicesOfferedV2({
  id,
  cardDetail,
}) {
  const classes = useStyles();

  const handleWhatsappShare = (whatsappNumber, name, serviceTitle) => {
    if (whatsappNumber == "" && whatsappNumber.length == 10) return;
    const text = `Hi ${name}, I want to enquire about ${serviceTitle}`;
    console.log("text", text);
    const encodedText = encodeURIComponent(text);
    handleOpenLink(`https://wa.me/91${whatsappNumber}?text=${encodedText}`);
  };


  const serviceBox = (key, title, desc, mobile, name) => {
    return (
      <Paper key={key} className={classes.serviceBoxV2}>
        {/* <Box style = {{'width': '100px'}}>
          <img
            src={imgSrc}
            className={classes.serviceBoxV2Img}
            // height={60}
          />
        </Box> */}
        <Box className={classes.serviceBoxV2Details}>
          <Box className={classes.serviceBoxV2Content}>
            <Box className={classes.serviceBoxHeading}>{title}</Box>
            <Box>{desc}</Box>
          </Box>
          <Box className={classes.serviceBoxV2BtnFlex}>
            {/* <Box>
              <IconButton className={classes.serviceBoxV2Icon}>
                <InfoOutlinedIcon style={{ paddingTop: 0 }} />
              </IconButton>
            </Box> */}
            <Box
              onClick={() =>
                handleWhatsappShare(mobile.substring(4), name, title)
              }
              className={classes.serviceBoxV2Btn}
            >
              Enquiry
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  };

  return (
    <Container maxWidth={"sm"} className={classes.root}>
      <Box id={id} className={classes.topDrop}>
        <Box className={classes.topDropText}>Services Offered</Box>
      </Box>

      <Box className={classes.flexBox}>
        <Box id="serviceScroll" className={classes.serviceScroll}>
          {cardDetail.servicesOffered &&
            cardDetail.servicesOffered.length > 0 &&
            cardDetail.servicesOffered.map((service, key) =>
              serviceBox(
                key,
                service.title,
                service.description,
                cardDetail.mobile,
                cardDetail.firstName
              )
            )}
        </Box>
      </Box>
    </Container>
  );
}

export default ServicesOfferedV2;
