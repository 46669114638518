import { API, BASE_URL } from ".";

export const GENERATE_VISITING_CARD_URL = `${BASE_URL}/cards/visiting-card/create`;

export const getVisitingCardPrefilledData = () => API.get(`/cards/visiting-card/prefilled-data`);

export const generateVisitingCard = (data) => API.post("/cards/visiting-card/create", data);

export const getAllCards = () => API.get("/cards/all");

export const getVistingCardDetail = (cardId) => API.get(`/cards/visiting-card/get/${cardId}`);

export const updateVisitingCard = (data, cardId) => API.post(`/cards/visiting-card/update/${cardId}`, data);

export const getVisitingCardView = (uniqueId) => API.get(`/cards/visiting-card/${uniqueId}`);
