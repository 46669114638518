import {
  Box,
  Container,
  Divider,
  IconButton,
  Input,
  InputAdornment,
  Link,
  List,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "./Style";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { getCardLink, isPresent } from "../../../../utils/helper";
import CompanyLogo from "../../../../assets/Logo_Synclink.svg";
import doubleQuotes from '../../../../assets/double_quotes.svg'
import phonePayIcon from  '../../../../assets/phonepe-icon.svg'
import paytmIcon from  '../../../../assets/paytm-icon.svg'
import gpayIcon from  '../../../../assets/google-pay-icon.svg'


function Payment({ id, cardDetail }) {
  const classes = useStyles();
  const [whatsappNumber, setWhatsappNumber] = useState("");

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    var el = document.getElementById("copiedText");

    el.style.display = "block";
    setTimeout(() => {
      el.style.display = "none";
    }, 4000);
  };

  const handleOpenLink = (link) => {
    window.open(link, "_blank");
  };

  return (
    <Container maxWidth={"sm"} className={classes.root} style={{display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%'}}>
      <Box>
      <Box id={id} className={classes.topDrop}>
        <Box className={classes.topDropText}>Payment & Testimonials</Box>
      </Box>

      <Box className={classes.flexBox}>
        {isPresent(cardDetail, cardDetail.upiId) && (
          <>
            <Box className={classes.subheading}>UPI Id</Box>
            {/* <img
                src="https://cdn.ttgtmedia.com/rms/misc/qr_code_barcode.jpg"
                width={120}
                style={{ marginTop: 20 }}
              /> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box>{cardDetail.upiId ? cardDetail.upiId : ""}</Box>
              <IconButton onClick={() => handleCopy(cardDetail.upiId)}>
                <CopyAllIcon className={classes.upiCopyIcon} />
              </IconButton>
              <Box
                id="copiedText"
                style={{
                  fontSize: "10px",
                  position: "absolute",
                  display: "none",
                  right: "-30px",
                }}
              >
                Copied!
              </Box>
            </Box>
            <Box>
              <img
                src={gpayIcon}
                style={{ transform: "scale(0.8)" }}
                width={50}
                height={50}
              />
              <img
                src={phonePayIcon}
                style={{ transform: "scale(0.6)" }}
                width={50}
                height={50}
              />
              <img
                src={paytmIcon}
                style={{ transform: "scale(0.9)" }}
                width={50}
                height={50}
              />
            </Box>
          </>
        )}
      </Box>
      {cardDetail && (isPresent(cardDetail, cardDetail.testimonialDescription) && 
        isPresent(cardDetail, cardDetail.testimonialAuthor) && (
          <Box style={{marginTop: 20, paddingLeft:30, paddingRight: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Box className={classes.subheading}>Testimonials</Box>
          <Paper className={classes.testimonialBox}>
            <img src={doubleQuotes} className={classes.doubleQuotes}/>
            <Typography className={classes.testimonialText}>
              {cardDetail.testimonialDescription}
            </Typography>
            <Divider style={{borderColor:'#f3f4f7', margin: 5}}/>
            <Box className={classes.testimonialAuthor}>
              <Box style={{fontWeight: 500}}>{cardDetail.testimonialAuthor}</Box>
              <Box>{cardDetail.testimonialAuthorOccupation}</Box>
            </Box>
          </Paper>
        </Box>
        )
      )}



      {cardDetail &&
        (cardDetail.facebookLink != "" ||
          cardDetail.twitterLink != "" ||
          cardDetail.youtubeLink != "" ||
          cardDetail.linkedinLink != "") && (
          <Box
            style={{
              marginTop: 20,
              marginBottom: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box className={classes.subheading}>Connect with us</Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
              style={{ marginTop: 20, width: "70%" }}
            >
              {isPresent(cardDetail, cardDetail.facebookLink) && (
                <IconButton
                  onClick={() => handleOpenLink(cardDetail.facebookLink)}
                >
                  <FacebookIcon
                    style={{ color: "#316FF6" }}
                    className={classes.footerIcon}
                  />
                </IconButton>
              )}
              {isPresent(cardDetail, cardDetail.twitterLink) && (
                <IconButton
                  onClick={() => handleOpenLink(cardDetail.twitterLink)}
                >
                  <TwitterIcon
                    style={{ color: "#1DA1F2" }}
                    className={classes.footerIcon}
                  />
                </IconButton>
              )}
              {isPresent(cardDetail, cardDetail.youtubeLink) && (
                <IconButton
                  onClick={() => handleOpenLink(cardDetail.youtubeLink)}
                >
                  <YouTubeIcon
                    style={{ color: "#CD201F" }}
                    className={classes.footerIcon}
                  />
                </IconButton>
              )}
              {isPresent(cardDetail, cardDetail.linkedinLink) && (
                <IconButton
                  onClick={() => handleOpenLink(cardDetail.linkedinLink)}
                >
                  <LinkedInIcon
                    style={{ color: "#0077b5" }}
                    className={classes.footerIcon}
                  />
                </IconButton>
              )}
            </Box>
          </Box>
        )}
      </Box>

      <Box className={classes.digitalCardIconBottom}>
        <Box className={classes.digitalCardFlex}>
          <Typography>Made using</Typography>
          <Link href="https://synclink.in"><Box className={classes.digitalCardIcon}>
            <img style={{maxWidth: '100%'}} src={CompanyLogo} />
          </Box>
          </Link>
        </Box>
        <Box className={classes.digitalCopyrightInfo}>Copyright &#169; 2024</Box>
      </Box>
    </Container>
  );
}

export default Payment;
