import { API } from ".";

export const getDigitalCardPrefilledData = () => API.get(`/cards/digital-card/prefilled-data`);

export const generateDigitalCard = (data) => API.post("/cards/digital-card/create", data);

export const getDigitalCardDetail = (cardId) => API.get(`/cards/digital-card/get/${cardId}`);

export const updateDigitalCard = (data, cardId) => API.post(`/cards/digital-card/update/${cardId}`, data);

export const getDigitalCardView = (uniqueId) => API.get(`/cards/digital-card/${uniqueId}`);
