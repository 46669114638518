import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { activateAccount } from "../../api/User";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../redux/actions/auth";

function AccountActivate() {
  const { token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch()

  useEffect(() => { 
    activateAccount(token)
      .then((res) => {
        if (res.status == 200 && res.data) {
          dispatch(showSnackbar(res.data.message, "success"))
        } else {
          dispatch(showSnackbar(res.data.message, "error"))
        }
        setTimeout(() => {
          history.push("/login");
        }, 2000);
      })
      .catch((err) => {
        if (err.response.data) {
          dispatch(showSnackbar(err.response.data.message, "error"))
        } else {
          dispatch(showSnackbar("Something went wrong", "error"))
        }
        setTimeout(() => {
          history.push("/");
        }, 1500);
      });
  }, []);

  return (
    <div>Please wait, we are validating you details...</div>
  );
}

export default AccountActivate;
