import React, { useState } from "react";
import { useStyles } from "./LoginPageStyle.js";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Button, Container, TextField } from "@mui/material";
import Header from "../Homepage/Header/Header.js";
import { forgotPassword } from "../../redux/actions/auth.js";

function ForgotPassword() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();


  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDefaultState = () => {
    setEmail("")
  }

  const handleReset = (e) => {
    e.preventDefault();
    if (email == "") return
    setLoading(true)
    const data = {email: email}
    dispatch(forgotPassword(data, setLoading, handleDefaultState))
  };


  return (
    <><Header callback={() => history.push('/login')}/>
    <Container className={classes.container}>
    <Box className={classes.loginBox}>
      <Box className={classes.flexBox}>
        <Box className={classes.heading}>Forgot</Box>
        <Box className={classes.heading}>Password</Box>
        <Box className={classes.subHeading}>Enter your Registered Email Id</Box>
        <Box className={classes.inputField}>
          <TextField
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            fullWidth
            id="outlined-required"
            label="Email *"
          />
        </Box>
      </Box>
      <Box>
        <Button
          disabled={loading}
          onClick={handleReset}
          className={classes.primaryButton}
        >
          Send Reset Link
        </Button>
      </Box>
    </Box>
    </Container>
    </>
  );
}

export default ForgotPassword;
