import React, { useEffect, useState } from "react";
import { useStyles } from "./Style";
import { Box, Container, IconButton, Link, List, ListItem } from "@mui/material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { isPresent } from "../../../../utils/helper";

function Annoucements({
  id,
  cardDetail,
}) {
  const classes = useStyles();

  return (
    <Container maxWidth={"sm"} className={classes.root}>
      <Box id={id} className={classes.topDrop}>
        <Box className={classes.topDropText}>Annoucements</Box>
      </Box>

      <Box className={classes.flexBox}>
        <Box className={classes.subheading}>General annoucements</Box>
        <List id="annoucementList" className={classes.annoucementList}>
          {cardDetail.announcements && cardDetail.announcements.length > 0 &&
            cardDetail.announcements.map((it, key) => (
              <ListItem key={key} className={classes.annoucementListItem}>
                <Box className={classes.announcementListDate}>
                  {new Date(it.createdAt).toLocaleDateString()}
                </Box>
                {isPresent(it, it.link) ? (
                  <Link
                    href={it.link}
                    className={classes.annoucementListItemText}
                  >
                    {it.announcement}
                  </Link>
                ) : (
                  <Box className={classes.annoucementListItemText}>
                    {it.announcement}
                  </Box>
                )}
              </ListItem>
            ))}
        </List>
      </Box>

      {/* <Box
        className={classes.bottomDrop}
        onClick={() => {
          handlePageChange(4);
        }}
      >
        <Box className={classes.bottomDropText}>Payment</Box>
        <IconButton color="white">
          <KeyboardDoubleArrowDownIcon
            style={{ color: "white", paddingTop: 0 }}
          />
        </IconButton>
      </Box> */}
    </Container>
  );
}

export default Annoucements;
