import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  // Home
  container: {
    borderTop: "12px solid",
    borderColor: theme.palette.secondary.main,
    minHeight: "100vh",
    backgroundColor: theme.palette.primary.background,
    position: "relative",
    padding: 0,
    fontFamily: "Inter",
    maxWidth: "600px",
    paddingBottom: 100
  },
  bottomDrop: {
    height: theme.typography.pxToRem(80),
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    width: "100vw",
    bottom: 0,
    borderTopLeftRadius: theme.typography.pxToRem(20),
    borderTopRightRadius: theme.typography.pxToRem(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    maxWidth: "100%",
  },
  detailsFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  companyName: {
    fontSize: theme.typography.pxToRem(26),
    color: theme.palette.primary.main,
    fontWeight: "700",
    marginTop: 60,
    width: '95%',
    textAlign: 'center',
    textTransform: 'capitalize'
  },
  companyLogo: {
    margin: 20,
    '& img': {
      objectFit: 'container',
      objectPosition: 'center',
      maxHidth: 100, 
      maxHeight: 100,
    }
  },
  person: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: "500",
    textAlign: "center",
    wordSpacing: 2,
    margin: 7,
  },
  qualification: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: "400",
    textAlign: "center",
    wordSpacing: 2,
    margin: 7,
  },
  iconContainer: {
    width: "80vw",
    display: "flex",
    justifyContent: "space-evenly",
    margin: 20,
    maxWidth: "100%",
  },
  iconBox: {
    color: theme.palette.primary.white,
    background: theme.home.primary.figmaBlue,
    borderRadius: 100,
    padding: 10,
    boxShadow: "0 6px 12px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);",
    "&:hover": {
      backgroundColor: theme.home.primary.figmaBlue,
    },
  },
  description: {
    width: "80%",
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "500",
    padding: 20,
  },
  serviceList: {
    paddingLeft: 10,
    listStyleType: "disc",
  },
  serviceListItem: {
    fontFamily: "Inter",
    paddingLeft: 2,
    paddingBottom: 0,
    paddingTop: 5,
    display: "list-item",
    margin: 4,
  },
  serviceListItemText: {
    textDecoration: "underline",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "500",
    display: "inline-block",
  },
  bottomDropText: {
    color: theme.palette.primary.white,
    fontSize: theme.typography.pxToRem(18),
  },
  addContact: {
    position: "fixed",
    bottom: theme.typography.pxToRem(45),
    right: theme.typography.pxToRem(20),
    background: "#212529",
    padding: theme.typography.pxToRem(5),
    borderRadius: 100,
    zIndex: 100,
  },
  scrollToTop: {
    position: "fixed",
    bottom: theme.typography.pxToRem(100),
    right: theme.typography.pxToRem(20),
    background: "#63AE49",
    padding: theme.typography.pxToRem(5),
    borderRadius: 100,
  },

  // About Us
  aboutUsSection: {
    padding: 30,
    paddingTop: theme.typography.pxToRem(90),
  },
  aboutUsDetail: {
    margin: 5,
    fontWeight: "500",
  },
  aboutUsImages: {
    display: "grid",
    gridAutoFlow: "row",
    gridTemplateColumns: "repeat(3, 1fr)",
    // gridTemplateRows: 'repeat(2, 50px)',
    gap: 3,
  },

  //   Services Offered
  root: {
    borderColor: theme.palette.secondary.main,
    minHeight: "100vh",
    backgroundColor: theme.palette.primary.background,
    position: "relative",
    padding: 0,
    fontFamily: "Inter",
  },
  topDrop: {
    height: theme.typography.pxToRem(60),
    backgroundColor: theme.home.primary.figmaBlue,
    position: "absolute",
    width: "100vw",
    top: 0,
    borderBottomLeftRadius: theme.typography.pxToRem(20),
    borderBottomRightRadius: theme.typography.pxToRem(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%",
  },
  topDropText: {
    color: theme.palette.primary.white,
    fontSize: theme.typography.pxToRem(20),
  },

  flexBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingTop: theme.typography.pxToRem(90),
  },
  horizontalScroll: {
    overflowX: "scroll",
    overflow: "scroll",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100vw",
    padding: 20,
  },
  serviceBox: {
    height: theme.typography.pxToRem(200),
    width: theme.typography.pxToRem(150),
    padding: 10,
    margin: 7,
  },
  serviceBoxHeading: {
    fontWeight: "600",
    fontSize: theme.typography.pxToRem(16),
    marginBottom: 10,
  },
  serviceBoxImage: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    height: theme.typography.pxToRem(140),
  },
  serviceBoxBtnFlex: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  serviceBoxBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.white,
    fontSize: theme.typography.pxToRem(12),
    margin: 5,
    padding: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  serviceBoxIcon: {
    color: "#1e577a",
    borderRadius: 100,
  },

  // Announcement
  announcementsText: {
    color: "#444444",
  },
  annoucementList: {
    // listStyleType: 'disc',
    width: "90%",
    fontWeight: "500",
    // height: "90vh",
    // overflowY: "scroll",
    paddingBottom: 50,
    marginTop: 10,
  },
  annoucementListItem: {
    fontFamily: "Inter",
    paddingLeft: 2,
    paddingBottom: 0,
    paddingTop: 5,
    margin: 4,
    display: "flex",
    flexDirection: "column",
  },
  announcementListDate: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "500",
    textAlign: "right",
    width: "100%",
    color: "#454343",
  },
  annoucementListItemText: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "500",
    marginBottom: 5,
    textAlign: 'left',
    width: '100%'
  },

  // Contact us
  contactUs: {
    margin: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contactUsBox: {
    background: theme.palette.primary.white,
    borderRadius: 10,
    padding: 15,
    width: "95%",
    margin: 10,
  },
  contactUsIconBox: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 10,
    // alignItems: 'center'
  },
  contactUsIcon: {
    color: theme.palette.primary.main,
    border: "1.5px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: 100,
    transform: "scale(1.3)",
    marginRight: 10,
    marginTop: 6,
    padding: 2,
  },
  subheading: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontWeight: "600",
    fontSize: theme.typography.pxToRem(17),
  },

  // Send whatsapp
  shareBox: {
    margin: 10,
    padding: 15,
    paddingTop: 0
  },
  shareBoxHeadingFlex: {

  },
  shareBoxHeading: {
    fontWeight: 400,
    marginTop: 15,
    color: theme.palette.primary.main
  },
  shareBoxInput: {
    background: theme.palette.primary.white,
    border: 0,
    outline: 0,
    borderRadius: 10,
    '& fieldset': {
      border: 'none',
      outline: 'none',
      borderRadius: 10
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${theme.palette.primary.main} !important`
    }
  },
  // Footer
  footer: {
    backgroundColor: theme.palette.primary.main,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  footerHeading: {
    fontWeight: "500",
    color: "#E7EBEE"
  },
  copyrightInfo: {
    fontSize: theme.typography.pxToRem(13),
    color: "#E7EBEE",
  },
  footerIcon: {
    color: theme.home.primary.figmaBlue,
    transform: 'scale(1.2)'
    // margin: 10,
  },

  // ServicesV2
  toggleGrp: {
    "& .Mui-selected": {
      borderColor: `${theme.palette.secondary.main} !important`,
      color: `${theme.palette.secondary.main} !important`,
      background: "transparent !important",
    },
    marginBottom: 15,
  },
  toggleBtn: {
    textTransform: "capitalize",
    // border: 'none',
    fontWeight: "500",
    padding: "5px 11px",
  },
  toggleBtnText: {
    // color: theme.palette.secondary.main,
    textAlign: "center",
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(17),
  },
  serviceScroll: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // overflowY: "scroll",
    // height: "90vh",
    paddingBottom: 100,
  },
  serviceBoxV2: {
    // height: theme.typography.pxToRem(80),
    width: "90%",
    padding: 10,
    margin: 10,
    display: "flex",
    alignItems: "flex-start",
  },
  serviceBoxV2Img: {
    maxHeight: "60px",
    maxWidth: "100px",
  },
  serviceBoxV2Details: {
    marginLeft: 10,
    display: "flex",
    width: "100%",
  },
  serviceBoxV2Content: {
    width: "75%",
    textWrap: "wrap",
    // overflow: 'hidden',
    // height: theme.typography.pxToRem(65),
  },
  serviceBoxHeading: {
    fontWeight: "600",
    fontSize: theme.typography.pxToRem(16),
    marginBottom: 10,
    marginRight: 3,
  },
  serviceBoxV2BtnFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "20%",
  },
  serviceBoxV2Btn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.white,
    fontSize: theme.typography.pxToRem(12),
    // margin: 5,
    padding: 5,
    paddingLeft: 8,
    paddingRight: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  serviceBoxV2Icon: {
    color: "#1e577a",
    borderRadius: 100,
    alignSelf: "end",
    padding: 0,
  },
  expiredTextBox: {
    position: "absolute",
    zIndex: 10,
    top: 0,
    height: '100vh',
    background: 'transparent'
  },
  expiredText: {
    zIndex: 5,
    textAlign: "center",
    backgroundColor: theme.palette.primary.white,
    width: '100%',
    padding: 10,
    marginTop: '40vh',
    fontSize: theme.typography.pxToRem(18),
  },

  // Testimonial
  testimonialBox: {
    width: '90%',
    marginTop: 20,
    padding: 20,
    borderRadius: 8
  },

  testimonialText: {
    color: theme.home.primary.darkGrey
  },
  testimonialAuthor: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 10,
    // color: theme.palette.secondary.main,
  },
  doubleQuotes: {
    height: 50,
    opacity: 0.5
  },

  // Payment
  digitalCardIconBottom: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 90
  },
  digitalCardFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  digitalCardIcon: {
    maxWidth: 90,
    maxHeight: 90,
    marginLeft: 5
    // margin: 25,
    // '& img': {
    //   objectFit: 'container',
    //   objectPosition: 'center',
    //   maxHidth: 90, 
    //   maxHeight: 90,
    // }
  },
  digitalIconImg: {
    maxWidth: '100%',
    // maxHeight: 90,
  },
  digitalCopyrightInfo: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.primary.black
  },

  // Gallery
  galleryGrid: {
    padding: 20,
    paddingTop: theme.typography.pxToRem(90),
    paddingBottom: theme.typography.pxToRem(60),
    columns: 2,
    columnGap: 15,
    '@media screen and (min-width: 500px)': {
      columns: 3,
  },
  },
  galleryImageGrid: {
    width: '100%',
    marginBottom: 10,
    breakInside: 'avoid '
  },
  galleryImagePreview:{
    maxWidth: '100%',
    borderRadius: 10
  },

  popupImageBox: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    background: 'rgba(0,0,0,0.9)',
    zIndex: 5
  },
  popupImageCloseIcon: {
    position: 'absolute',
    top: 30,
    right: 30
  },
  popupImage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '80vh',
    maxWidth: '80vw'
  }  
}));


