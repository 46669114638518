import React, { useRef } from "react";
import { useStyles } from "./StepStyle";
import Step1 from "../../assets/step1.svg";
import Step2 from "../../assets/step2.svg";
import Step3 from "../../assets/step3.svg";
import { Box, IconButton } from "@mui/material";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

function Steps() {
  const classes = useStyles();
  const slider = useRef();

  const handleRightScroll = () => {
    slider.current.scrollLeft += 100
  }

  const handleLeftScroll = () => {
    slider.current.scrollLeft -= 100
  }

  return (
    <>
      <Box className={classes.contentBox}>
        <Box className={classes.contentHeading}>GET STARTED QUICKLY</Box>
        <Box ref={slider} className={classes.stepFlex}>
          <Box className={classes.step}>
            <img
              className={classes.stepImage}
              src={Step1}
              alt="Step 1"
              loading="lazy"
            />
            <Box>Join in the community of professionals by signing up</Box>
          </Box>
          <Box className={classes.step}>
            <img
              className={classes.stepImage}
              src={Step2}
              alt="Step 2"
              loading="lazy"
            />
            <Box>Fill up the necessary information only, and we will handle the rest </Box>
          </Box>
          <Box className={classes.step}>
            <img
              className={classes.stepImage}
              src={Step3}
              alt="Step 3"
              loading="lazy"
            />
            <Box>Build meaningful and long-term impression in your network</Box>
          </Box>
        </Box>
        <Box className={classes.arrowFlex}>
          <IconButton onClick={handleLeftScroll}><ArrowCircleLeftOutlinedIcon className={classes.arrowIconLeft}/></IconButton>
          <IconButton onClick={handleRightScroll}><ArrowCircleRightOutlinedIcon className={classes.arrowIconRight}/></IconButton>
        </Box>
      </Box>
      </>
  );
}

export default Steps;
