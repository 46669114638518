import { makeStyles } from "@mui/styles";


export const VisitingCardStyles = makeStyles((theme) => ({
  companyName: {
    fontSize: theme.typography.pxToRem(26),
    color: theme.palette.primary.main,
    fontWeight: "700",
    marginTop: 50,
    width: '95%',
    textAlign: 'center',
    textTransform: 'capitalize'
  },
  footerHeading: {
    marginBottom: 10,
    fontWeight: "500",
    color: "#E7EBEE",
    padding: 0,
    textTransform: 'capitalize'
  },
  contactUsBox: {
    background: theme.palette.primary.white,
    borderRadius: 10,
    padding: 15,
    width: "90%",
    margin: 20,
  },
  addContact: {
    position: "fixed",
    bottom: theme.typography.pxToRem(100),
    right: theme.typography.pxToRem(20),
    background: "#212529",
    padding: theme.typography.pxToRem(5),
    borderRadius: 100,
    zIndex: 100,
  },
  shareBox: {
    marginTop: 15,
    margin: 20
  },
  editCardBtn: {
    position: 'absolute',
    top: 15,
    left: 20,
    textTransform: 'initial',
    padding: '4px 6px',
    paddingLeft: 0
  },
  shareCardBtn: {
    position: 'absolute',
    top: 15,
    right: 20,
    padding: '4px 6px',
    paddingLeft: 0,
    textTransform: 'initial',
  },

  // Share Modal
  shareModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '85%',
    padding: 30,
    background: theme.palette.primary.white,
    borderRadius: 10,
    boxShadow: 2
  },
  modelButtonFlex: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    width: '100%'
  },
  shareBtnFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  shareBtn: {
    backgroundColor: '#f8f9fa',
    padding: 15,
    borderRadius: '50%'
  },
  shareBtnText: {
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'center',
    marginTop: 3
  },
  pageLink: {
    fontWeight: 500
  },
  pageLinkCopy: {
    backgroundColor: '#e4f2f7',
    marginTop: 15,

    '& fieldset': {
      outline: 'none',
      border: 'none',
    },
    borderRadius: 4
  },
  linkCopiedTxt: {
    fontSize: theme.typography.pxToRem(14),
    opacity: 0.7,
    display: "none",
    textAlign: 'right',
  },
  modalShareBox: {
    border: `1px solid ${theme.home.primary.grey}`,
    borderRadius: 10,
    marginTop: 10
  }
}));
