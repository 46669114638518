import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 30,
    paddingTop: 80,
    fontFamily: "Inter",
    height: "100vh",
  },
  loginBox: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  flexBox: {
    marginBottom: 20,
  },
  heading: {
    color: theme.home.primary.background,
    fontSize: theme.typography.pxToRem(40),
    fontWeight: "700",
    letterSpacing: 2,
  },
  subHeading: {
    color: theme.home.primary.grey,
    fontWeight: "600",
    fontSize: theme.typography.pxToRem(18),
    marginTop: 30,
    marginBottom: 25,
    letterSpacing: 1.5,
  },
  inputField: {
    marginBottom: 10,
    "& .MuiFormControl-root": {
      background: "#f0eff5",
      border: 0,
    },
  },
  forgetPass: {
    textAlign: "right",
    textTransform: 'capitalize',
    float: 'right'
  },
  extraText: {
    color: theme.home.primary.grey,
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(16),
    marginTop: 50,
    letterSpacing: 1,
    textAlign: "center",
  },
  signUpText: {
    color: theme.home.secondary.blueButtonBg,
  },
  primaryButton: {
    textTransform: "uppercase",
    backgroundColor: theme.home.secondary.blueButtonBg,
    display: "flex",
    flex: 1,
    width: "100%",
    alignSelf: "center",
    color: theme.palette.primary.white,
    letterSpacing: 2,
    padding: 10,
    "&:hover": {
      backgroundColor: theme.home.secondary.blueButtonBg,
    },
  },
  loaderBox: {
    flex: 0.6,
  },
  loaderBoxText: {
    fontSize: theme.typography.pxToRem(20)
  },
  loader: {
    position: "absolute",
    top: "calc(50% - 2em)",
    left: "calc(50% - 2em)",
    width: "4em",
    height: "4em",
    border: "0.7em solid rgba(0, 0, 0, 0.2)",
    borderLeft: "0.7em solid #000000",
    borderRadius: "50%",
    animation: `$load8 1.1s infinite linear`,
    zIndex: 2,
  },
  "@keyframes load8": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));
