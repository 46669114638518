import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    footer: {
        width: '100%',
        background: theme.palette.primary.black,
        color: theme.palette.primary.white,
        padding: '30px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    companyLogo: {
        color: theme.palette.primary.white,
        '& img': {
            maxWidth: '150px'
        }
    },
    companyName: {
        transform: 'uppercase',
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(26),
        marginTop: 15 
    },
    contactUs: {
        color: theme.home.primary.darkGrey,
        marginTop: 20
    },
    contactUsIconBox: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    contactUsText: {
        color: theme.palette.primary.white
    },
    icon: {
        marginRight: 10,
        color: theme.palette.primary.white
    },
    copyright: {
        marginTop: 20,
        fontSize: theme.typography.pxToRem(14),
    }
}))