import { VISITING_CARD } from "../redux/const/types";

export const getCardLink = (uniqueId, type) => {
  const index = window.location.href.indexOf("/cards");
  const cardPrefix = type == VISITING_CARD ? "/visiting-card/" : "/";
  const site = window.location.href.substring(0, index).trim(); // or use inputString.slice(0, index).trim();
  const url = site + cardPrefix + uniqueId;
  return url;
};

export const isPresent = (cardDetail, field) => {
  return cardDetail && field != undefined && field != "" && field != null;
};

export const handleOpenLink = (link) => {
  let url = link
  if (!url.match(/^https?:\/\//i)) {
        url = 'http://' + url;
    }
  window.open(url, "_blank");
};

export const handleSystemLinkOpen = (url) => {
  window.open(url, "_blank");
}


export const getRandomId = () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
}