import { Box, Container, Paper } from '@mui/material'
import React from 'react'
import {useStyles} from './PrivacyPolicyStyles'
import Header from '../Header/Header';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Footer from '../Footer/Footer';

function TermsConditions() {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box className={classes.container}>
      <Header callback={() => history.push("/")} text={"Terms and Conditions"} isBlue={true}/>
      <Box className={classes.header}>
        <Box className={classes.heading}>Terms and Conditions</Box>
        <Box>Welcome to Synclink! These terms and conditions outline the rules and regulations for the use of our website, including online payment services. By accessing this website, we assume you accept these terms and conditions.</Box>
        <Box className={classes.subHeading}>Online Payments:</Box>
        <Box>Our website offers online payment services for the products and services offered. By using these services, you agree to provide accurate and complete information for all transactions. You also agree to be responsible for any charges incurred through your account.</Box>
        <Box className={classes.subHeading}>Refunds:</Box>
        <Box>We generally do not accept refunds for products or services purchased through our website. However, we may consider refund requests on a case-by-case basis for exceptional circumstances. If a refund is approved, it will be processed within 4-5 business days.</Box>
        <Box className={classes.subHeading}>Intellectual Property:</Box>
        <Box>All content, including but not limited to text, graphics, logos, images, and software, on this website is the property of Synclink or its content suppliers and is protected by international copyright laws.</Box>
        <Box className={classes.subHeading}>Changes to Terms and Conditions:</Box>
        <Box>Synclink reserves the right to revise these terms and conditions at any time without notice. By using this website, you are agreeing to be bound by the then-current version of these Terms and Conditions.</Box>
        <Box className={classes.subHeading}>Contact Information:</Box>
        <Box>If you have any questions or concerns regarding these terms and conditions, please contact us at support@synclink.in</Box>
        <Box className={classes.subHeading}>Last updated on: 22th March 2024</Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default TermsConditions