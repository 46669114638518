import React, { useState } from "react";
import { useStyles } from "./LoginPageStyle";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { hideSnackbar, signin, signup } from "../../redux/actions/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../Homepage/Header/Header";

function LoginPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showLogin, setShowLogin] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleOpenLogin = () => {
    setShowLogin(true);
  };

  const handleOpenSignUp = () => {
    setShowLogin(false);
  };

  const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = (e) => {
      e.preventDefault();
      if (email !== "" && password !== "") {
        dispatch(hideSnackbar())
        setLoading(true);
        dispatch(signin({ email, password }, history, setLoading));
      }
    };

    return (
      <Box className={classes.loginBox}>
        {loading ? (
          <Box className={classes.loaderBox}>
            <Typography className={classes.loaderBoxText}>
              Please wait, we are logging you in!
            </Typography>
            <Box className={classes.loader}></Box>
          </Box>
        ) : (
          <>
            <Box className={classes.flexBox}>
              <Box className={classes.heading}>Welcome</Box>
              <Box className={classes.heading}>Back</Box>
              <Box className={classes.subHeading}>Sign in to continue</Box>
              <Box className={classes.inputField}>
                <TextField
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  fullWidth
                  id="outlined-required"
                  label="Email *"
                />
              </Box>
              <Box className={classes.inputField}>
                <TextField
                  id="outlined-password-input"
                  value={password}
                  label="Password *"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  fullWidth
                />
              </Box>
              <Button onClick={() => history.push('/forgot-password')} className={classes.forgetPass}>Forgot Password?</Button>
            </Box>
            <Box>
              <Button
                disabled={loading}
                onClick={handleLogin}
                className={classes.primaryButton}
              >
                Login
              </Button>
              <Box className={classes.extraText}>
                Create new account?{" "}
                <span onClick={handleOpenSignUp} className={classes.signUpText}>
                  Sign Up
                </span>
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const Signup = () => {
    const InitState = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    };

    const dispatch = useDispatch();
    const [sForm, setsForm] = useState(InitState);

    const handleChange = (e) =>
      setsForm({
        ...sForm,
        [e.target.name]: e.target.value,
      });

    const handleDefaultState = () => {
      setsForm(InitState);
    };

    const handleOnSubmit = (e) => {
      e.preventDefault();
      if (
        sForm.firstName !== "" &&
        sForm.email !== "" &&
        sForm.phone !== "" &&
        sForm.password !== "" &&
        sForm.confirmPassword !== ""
      ) {
        dispatch(hideSnackbar())
        setLoading(true);
        dispatch(signup(sForm, history, setLoading, handleDefaultState));
      }
    };

    return (
      <Box className={classes.loginBox}>
        {loading ? (
          <Box className={classes.loaderBox}>
            <Typography className={classes.loaderBoxText}>
              Please wait, we are signing you up!
            </Typography>
            <Box className={classes.loader}></Box>
          </Box>
        ) : (
          <>
            <Box className={classes.flexBox}>
              <Box className={classes.heading}>Welcome</Box>
              <Box className={classes.heading}>User</Box>
              <Box className={classes.subHeading}>Sign up to join</Box>
              <Box className={classes.inputField}>
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="First Name *"
                  onChange={handleChange}
                  name="firstName"
                />
              </Box>
              <Box className={classes.inputField}>
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="Last Name"
                  onChange={handleChange}
                  name="lastName"
                />
              </Box>
              <Box className={classes.inputField}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography>+91</Typography>
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-required"
                  label="Mobile *"
                  type="number"
                  fullWidth
                  onChange={handleChange}
                  name="phone"
                />
              </Box>
              <Box className={classes.inputField}>
                <TextField
                  id="outlined-required"
                  label="Email *"
                  fullWidth
                  onChange={handleChange}
                  name="email"
                />
              </Box>
              <Box className={classes.inputField}>
                <TextField
                  id="outlined-password-input"
                  label="Password *"
                  type="password"
                  autoComplete="current-password"
                  fullWidth
                  onChange={handleChange}
                  name="password"
                />
              </Box>
              <Box className={classes.inputField}>
                <TextField
                  id="outlined-password-input"
                  label="Confirm Password *"
                  type="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  name="confirmPassword"
                  fullWidth
                />
              </Box>
            </Box>
            <Box>
              <Button
                disabled={loading}
                className={classes.primaryButton}
                onClick={handleOnSubmit}
              >
                Sign Up
              </Button>
              <Box className={classes.extraText}>
                Have an account?{" "}
                <span onClick={handleOpenLogin} className={classes.signUpText}>
                  Sign In
                </span>
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  };

  return (
    <>
      <Header callback={() => history.push('/')}/>
      <Container className={classes.container}>
        {showLogin ? <Login /> : <Signup />}
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(LoginPage);
