import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useStyles } from "./LoginPageStyle";
import Header from "../Homepage/Header/Header";
import { Box, Button, Container, TextField } from "@mui/material";
import { resetPassword } from "../../redux/actions/auth";

function ResetPassword() {
  const classes = useStyles();
  const { token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")


  const handleReset =(e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(resetPassword({ password, confirmPassword }, history, setLoading, token));
  }

  return (
    <>
      <Header callback={() => history.push("/login")} />
      <Container className={classes.container}>
        <Box className={classes.loginBox}>
          <Box className={classes.flexBox}>
            <Box className={classes.heading}>Reset</Box>
            <Box className={classes.heading}>Password</Box>
            <Box className={classes.subHeading}>Please enter the new password</Box>
            <Box className={classes.inputField}>
              <TextField
                id="outlined-password-input"
                value={password}
                label="Password *"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                fullWidth
              />
            </Box>
            <Box className={classes.inputField}>
              <TextField
                id="outlined-password-input"
                value={confirmPassword}
                label="Confirm Password *"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoComplete="current-password"
                fullWidth
              />
            </Box>
          </Box>
          <Box>
            <Button
              disabled={loading}
              onClick={handleReset}
              className={classes.primaryButton}
            >
              Reset Password
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default ResetPassword;
