import React from "react";
import { useStyles } from "./Style";
import { Box, Container, IconButton, List, ListItem } from "@mui/material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

function AboutUs({ id, cardDetail }) {
  const classes = useStyles();

  return (
    <Container maxWidth={"sm"} className={classes.root}>
      <Box id={id} className={classes.topDrop}>
        <Box className={classes.topDropText}>About Us</Box>
      </Box>
      <Box className={classes.aboutUsSection}>
        <Box className={classes.aboutUsDetail} style={{lineHeight: 1.8, textAlign: 'justify'}}>
          {cardDetail.aboutCompany ? cardDetail.aboutCompany : ""}
        </Box>

        {cardDetail.specialities && cardDetail.specialities.length > 0 && (
          <Box style={{ marginTop: 30 }}>
            <Box className={classes.subheading}>Our Key Clients</Box>
            <List className={classes.annoucementList}>
              {cardDetail.specialities.map((it, index) => (<ListItem key={index} className={classes.serviceListItem}>
                <Box className={classes.annoucementListItemText}>
                  {it}
                </Box>
              </ListItem>))}
            </List>
          </Box>
        )}

        <Box className={classes.aboutUsImages}></Box>
      </Box>

      {/* <Box
        className={classes.bottomDrop}
        onClick={() => {
          handlePageChange(2);
        }}
      >
        <Box className={classes.bottomDropText}>Services Offered</Box>
        <IconButton color="white">
          <KeyboardDoubleArrowDownIcon
            style={{ color: "white", paddingTop: 0 }}
          />
        </IconButton>
      </Box> */}
    </Container>
  );
}

export default AboutUs;
