import { makeStyles } from "@mui/styles";
import background from  '../../assets/background.svg'
import frame from '../../assets/illustration.png'

export const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
        padding: 0,
        fontFamily: "Inter"
    },
    header: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    centerFlex: {
        background: `${theme.home.primary.background}`,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 30,
        height: '100vh',
        paddingBottom: 20,
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    topCenterFlex: {
        display: 'flex',
        flexDirection: 'column',
        height: '65vh',
    },
    companyLogo: {
        color: theme.palette.primary.white,
        '& img': {
            maxWidth: '150px'
        }
    },
    loginBtn: {
        padding: 5,
        paddingLeft: 15,
        paddingRight: 15,
        color: theme.palette.primary.white,
        borderRadius: 5,
        background: theme.home.secondary.blueButtonBg,
    },
    heading: {
        color: theme.home.primary.main,
        fontSize: theme.typography.pxToRem(32),    
        fontWeight: "700",
        textAlign: 'center',
        margin: 20,
        marginBottom: 30,
        marginTop: 40,
        lineHeight: 1.5,
    },
    description: {
        color: theme.home.primary.main,
        width: '90%',
        alignSelf: 'center',    
        fontSize: theme.typography.pxToRem(18),     
        marginBottom: 2,
        lineHeight: 1.5,
        fontWeight: 400
    },
    sampleImg: {
        transform: 'scale(0.8)'
    },
    subheading: {
        fontSize: theme.typography.pxToRem(18),      
        alignSelf: 'center',      
        fontWeight: "600",
        textAlign: 'center',
        // marginTop: 30,
        width: '90%',
        color: theme.home.primary.grey
    },
    createCardBtn: {
        textTransform: 'uppercase',
        backgroundColor: theme.palette.primary.white,
        width: '90%',
        alignSelf: 'center',      
        color: theme.palette.primary.black,
        letterSpacing: 2,
        fontWeight: 600,
        marginTop: 50,
        "&:hover": {
            backgroundColor: theme.palette.primary.white,
          },
    },
    primaryBox: {
    },
    illustrationBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10vh',
        '@media screen and (max-height: 700px)': {
            marginTop: '20vh',
        },
        height: '80vh',
    },
    illustration: {
        // height: '80vh',
        // width: '100%',
        // backgroundImage: `url(${frame})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center',
        // backgroundSize: 'contain',
        border: '5px solid black',
        borderRadius: 16,
        maxHeight: '80vh'
    },
    primaryBox: {
        marginTop: '60vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20,
        paddingTop: 0,
        '@media screen and (max-height: 700px)': {
            marginTop: '80vh',
        }
    },
    contentBox: {
        width: '90%',
        marginBottom: 20
    },
    contentHeading: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(18)
    },
    contentDescription: {
        marginTop: 8,
        color: theme.home.primary.darkGrey,
        // letterSpacing: 1.1
    },
    contentDescriptionHeading: {
        color: theme.palette.primary.black,
        fontWeight: 500
    },

    // Pricing box
    pricingBox: {
        border: '1px solid black',
        borderRadius: 8,
        width: '90%',
        marginBottom: 30,
        marginTop: 15,
        paddingBottom: 10
    },
    pricingBoxHeading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        padding: 10,
        backgroundColor: 'black',
        fontWeight: 600,
        color: theme.palette.primary.white,
        fontSize: theme.typography.pxToRem(20),
        borderTopRightRadius: 6,
        borderTopLeftRadius: 6
    },
    pricingBoxPrice: {
        marginTop: 15,
        fontSize: theme.typography.pxToRem(26),
        textAlign: 'center',
        color: theme.home.primary.darkGrey
    },
    pricingBoxList: {
        listStyleType: 'disc',
        marginLeft: 30,
        marginTop: 5,
        padding: 5
    },
    pricingBoxListItem: {
        padding: 0,
        display: 'list-item',
        color: theme.home.primary.darkGrey,
        marginBottom: 3
    },
    createCardSecondaryBtn: {
        textTransform: 'uppercase',
        backgroundColor: theme.palette.primary.black,
        width: '100%',
        alignSelf: 'center',      
        color: theme.palette.primary.white,
        letterSpacing: 1.5,
        fontWeight: 600,
        marginTop: 10,
        borderRadius: 8,
        "&:hover": {
            backgroundColor: theme.palette.primary.black,
          },
    }
}))