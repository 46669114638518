import React from "react";
import { useStyles } from "./HeaderStyle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Typography } from "@mui/material";

function Header({ text, callback, isBlue }) {
  const classes = useStyles();

  return (
    <Box className={classes.header} style={isBlue ? {backgroundColor: '#1E577A'} : undefined}>
      <IconButton className={classes.iconButton} onClick={callback}>
        <ArrowBackIcon style={isBlue ? {color: '#FFF'} : undefined} className={classes.backIcon}/>
      </IconButton>
      <Typography style={isBlue ? {color: '#FFF'} : undefined} className={classes.text}>{text}</Typography>
    </Box>
  );
}

export default Header;
