import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Input,
  Link,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { digitalCardStyles } from "./DigitalCardStyle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CustomizedSteppers from "./CreateStepper";
import {
  generateDigitalCard,
  getDigitalCardDetail,
  getDigitalCardPrefilledData,
  updateDigitalCard,
} from "../../../api/DigitalCard";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { CREATE_NEW, UPDATE_EXISTING } from "../../../redux/const/types";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../redux/actions/auth";
import Header from "../../Homepage/Header/Header";
import CALogo from "../../../assets/CA_Logo.png";
import { getRandomId, handleOpenLink } from "../../../utils/helper";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BpCheckbox } from "../../../components/Checkbox";

function CreateDigitalCard({ type }) {
  const classes = digitalCardStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { cardId } = useParams();

  const InitState = {
    prefix: "CA",
    firstName: "",
    lastName: "",
    qualification: "",
    mobile: "",
    alternateMobile: "",
    emailId: "",

    companyName: "",
    // companyLogo: "",
    // uploadedCompanyLogo: "",
    aboutCompany: "",
    officeAddress: "",
    alternateOfficeAddress: "",
    city: "",
    pincode: "",
    locationLink: "",
    upiId: "",

    testimonialDescription: "",
    testimonialAuthor: "",
    testimonialAuthorOccupation: "",

    websiteLink: "",
    facebookLink: "",
    twitterLink: "",
    youtubeLink: "",
    linkedinLink: "",

    specialityTitle: "",
    specialities: [],

    serviceOfferedTitle: "",
    serviceOfferedDesc: "",
    servicesOffered: [],

    announcementTitle: "",
    announcementLink: "",
    announcements: [],
  };

  const [form, setForm] = useState(InitState);
  const [defaultServicesOffered, setDefaultServicesOffered] = useState([]);
  const [defaultAnnouncement, setDefaultAnnouncement] = useState([]);
  const [defaultSpecialities, setDefaultSpecialities] = useState([]);

  const [servicesOffered, setServicesOffered] = useState([]);
  const [announcements, setAnnouncement] = useState([]);
  const [specialities, setSpecialities] = useState([]);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [galleryUploadError, setGalleryUploadError] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);


  const [companyLogo, setCompanyLogo] = useState({
    file: null,
    preview: CALogo
  })
  const [imageUploadError, setImageUploadError] = useState("");


  useEffect(() => {
    window.scrollTo(0, 0);
    if (type == CREATE_NEW) {
      getDigitalCardPrefilledData()
        .then((res) => {
          if (res.data) {
            if (res.data.servicesOffered) {
              const data = res.data.servicesOffered.map((it) => {
                return {
                  id: getRandomId(),
                  isChecked: true,
                  title: it.title,
                  description: it.description,
                };
              });
              setDefaultServicesOffered(data);
            }
            if (res.data.announcements) {
              const data = res.data.announcements.map((it) => {
                return {
                  id: getRandomId(),
                  isChecked: true,
                  announcement: it.announcement,
                  createdAt: it.createdAt,
                  link: it.link,
                };
              });
              setDefaultAnnouncement(data);
            }
            if (res.data.specialities) {
              const data = res.data.specialities.map((it) => {
                return {
                  id: getRandomId(),
                  isChecked: true,
                  specialityTitle: it,
                };
              });
              setDefaultSpecialities(data);
            }
            const updatedForm = {
              ...form,
              ...res.data,
            };
            setForm(updatedForm);
          }
        })
        .catch((err) => {});
    } else if (type == UPDATE_EXISTING && cardId != undefined) {
      getDigitalCardDetail(cardId)
        .then((res) => {
          if (res.data.galleryImages.length > 0){
            let previewGalleryImages = res.data.galleryImages.map((img) => {
              return {
                preview: img, 
                file: null
              }
            })
            setGalleryImages(previewGalleryImages)
          }

          if (res.data.servicesOffered) {
            const data = res.data.servicesOffered.map((it) => {
              return {
                id: getRandomId(),
                title: it.title,
                description: it.description,
              };
            });
            setServicesOffered(data);
          }
          if (res.data.announcements) {
            const data = res.data.announcements.map((it) => {
              return {
                id: getRandomId(),
                announcement: it.announcement,
                createdAt: it.createdAt,
                link: it.link,
              };
            });
            setAnnouncement(data);
          }
          if (res.data.specialities) {
            const data = res.data.specialities.map((it) => {
              return {
                id: getRandomId(),
                specialityTitle: it,
              };
            });
            setSpecialities(data);
          }
          if (res.data.companyLogo != ""){
            setCompanyLogo({
              file: null,
              preview: res.data.companyLogo
            })
          } else {
            if (res.data.prefix == "CA"){
              setCompanyLogo({
                file: null,
                preview: CALogo
              })
            } else {
              setCompanyLogo({
                file: null,
                preview: ""
              })
            }
          }
          // const imgSrc =
          //   res.data.companyLogo != ""
          //     ? res.data.companyLogo
          //     : res.data.prefix == "CA"
          //     ? CALogo
          //     : "";
          // console.log("imgSrc", imgSrc);
          // setPreviewImgSrc(imgSrc);
          const updatedForm = {
            ...form,
            ...res.data,
          };
          setForm(updatedForm);
        })
        .catch((err) => {
          dispatch(showSnackbar("Something went wrong", "error", 4000));
        });
    }
  }, [cardId]);

  const handleChange = (e) => {
    if (e.target.name == "prefix") {
      if (e.target.value == "CA") {
        setCompanyLogo({
          file: null,
          preview: CALogo
        })
      } else {
        setCompanyLogo({
          file: null,
          preview: ""
        })
      }
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddService = () => {
    if (form.serviceOfferedTitle != "") {
      const updatedServices = [
        ...servicesOffered,
        {
          id: getRandomId(),
          title: form.serviceOfferedTitle,
          description: form.serviceOfferedDesc,
        },
      ];
      setServicesOffered(updatedServices);
      const updatedForm = {
        ...form,
        serviceOfferedTitle: "",
        serviceOfferedDesc: "",
      };
      setForm(updatedForm);
    }
  };

  const handleRemoveService = (service) => {
    const updatedService = servicesOffered.filter((it) => it.id != service.id);
    setServicesOffered(updatedService);
  };

  const handleAddAnnouncement = () => {
    if (form.announcementTitle != "") {
      const updatedAnnoucement = [
        ...announcements,
        {
          id: getRandomId(),
          announcement: form.announcementTitle,
          createdAt: new Date(),
          link: form.announcementLink,
        },
      ];
      setAnnouncement(updatedAnnoucement);
      const updatedForm = {
        ...form,
        announcementTitle: "",
        announcementLink: "",
      };
      setForm(updatedForm);
    }
  };

  const handleRemoveAnnouncement = (annoucement) => {
    const updatedAnnoucement = announcements.filter(
      (it) => it.id != annoucement.id
    );
    setAnnouncement(updatedAnnoucement);
  };

  const handleAddSpeciality = () => {
    if (form.specialityTitle != "") {
      const updatedSpecialities = [
        ...specialities,
        { id: getRandomId(), specialityTitle: form.specialityTitle },
      ];
      setSpecialities(updatedSpecialities);
    }
    const updatedForm = {
      ...form,
      specialityTitle: "",
    };
    setForm(updatedForm);
  };

  const handleRemoveSpecialities = (speciality) => {
    const updatedSpeciality = specialities.filter(
      (it) => it.id != speciality.id
    );
    setSpecialities(updatedSpeciality);
  };

  const steps = [
    "Personal Details",
    "Company Details",
    "Key Clients",
    "Gallery",
    "Services Offered",
    "Announcements",
    "Testimonials",
  ];

  const handleSubmit = () => {
    const selectedServices = defaultServicesOffered.filter(
      (it) => it.isChecked
    );
    const submitServices = [...servicesOffered, ...selectedServices];
    const selectedAnnouncement = defaultAnnouncement.filter(
      (it) => it.isChecked
    );
    const submitAnnouncement = [...announcements, ...selectedAnnouncement];

    const selectedSpecialities = defaultSpecialities.filter(
      (it) => it.isChecked
    );
    const submitSpecialities = [...specialities, ...selectedSpecialities];

    const uploadedGalleryImages = galleryImages.map(it => it.file).filter(it => it != null)
    const existingGalleryImages = galleryImages.filter(it => it.file == null).map(it => it.preview)

    let uploadedCompanyLogo = ""
    let existingCompanyLogo = ""

    if (companyLogo.file != null){
      uploadedCompanyLogo = companyLogo.file
    } else {
      existingCompanyLogo = companyLogo.preview == CALogo ? "" : companyLogo.preview
    }

    const data = {
      prefix: form.prefix,
      firstName: form.firstName,
      lastName: form.lastName,
      qualification: form.qualification,
      mobile: form.mobile,
      alternateMobile: form.alternateMobile,
      emailId: form.emailId,
      companyName: form.companyName,
      companyLogo: existingCompanyLogo,
      uploadedGalleryImages: uploadedGalleryImages,
      existingGalleryImages: existingGalleryImages,
      uploadedCompanyLogo: uploadedCompanyLogo,
      aboutCompany: form.aboutCompany,
      officeAddress: form.officeAddress,
      alternateOfficeAddress: form.alternateOfficeAddress,
      city: form.city,
      pincode: form.pincode,
      locationLink: form.locationLink,
      upiId: form.upiId,
      facebookLink: form.facebookLink,
      twitterLink: form.twitterLink,
      youtubeLink: form.youtubeLink,
      linkedinLink: form.linkedinLink,
      websiteLink: form.websiteLink,
      servicesOffered: submitServices,
      announcements: submitAnnouncement,
      specialities: submitSpecialities,
      testimonialDescription: form.testimonialDescription,
      testimonialAuthor: form.testimonialAuthor,
      testimonialAuthorOccupation: form.testimonialAuthorOccupation,
    };

    setLoading(true);
    if (type == CREATE_NEW) {
      generateDigitalCard(data)
        .then((res) => {
          if (res.status == 200) {
            dispatch(
              showSnackbar("Card generated successfully", "success", "4000")
            );
            setTimeout(() => {
              history.push(`/${res.data.uniqueId}`);
            }, 1500);
          } else {
            setError(res.response.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status == 400) {
            setError(err.response.data.message);
            setLoading(false);
          } else {
            setError("Something went wrong, please try again");
            setLoading(false);
          }
        });
    } else if (type == UPDATE_EXISTING && cardId != undefined) {
      updateDigitalCard(data, cardId)
        .then((res) => {
          if (res.status == 200) {
            dispatch(
              showSnackbar("Card updated successfully", "success", 2500)
            );
            setTimeout(() => {
              history.push("/cards");
            }, 1500);
          } else {
            setError(res.response.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status == 400) {
            setError(err.response.data.message);
            setLoading(false);
          } else {
            setError("Something went wrong, please try again");
            setLoading(false);
          }
        });
    }
  };

  const handleNextStep = (currentStep) => {
    if (activeStep > steps.length - 1) return;
    let errorIt = "";
    switch (currentStep) {
      case 0: {
        if (form.firstName == "") {
          errorIt = "Please fill the first name field";
        } else if (form.emailId == "") {
          errorIt = "Please fill the email Id field";
        } else if (form.mobile == "" || form.mobile.toString().length != 10) {
          errorIt = "Please fill a valid mobile";
        }
        break;
      }
      case 1: {
        if (form.companyName == "") {
          errorIt = "Please fill the Company name";
        } else if (form.officeAddress == "") {
          errorIt = "Please fill the Office address";
        } else if (form.pincode == "") {
          errorIt = "Please fill the Pincode";
        }
        break;
      }
      case 2: {
        // const defaultCheckedSpecialities = defaultSpecialities.filter(
        //   (it) => it.isChecked
        // );
        // if (
        //   specialities &&
        //   specialities.length == 0 &&
        //   defaultCheckedSpecialities.length == 0
        // ) {
        //   errorIt = "Please add atleast one key Client";
        // }
        break;
      }
      case 4: {
        const defaultCheckedServices = defaultServicesOffered.filter(
          (it) => it.isChecked
        );
        if (
          servicesOffered &&
          servicesOffered.length == 0 &&
          defaultCheckedServices.length == 0
        ) {
          errorIt = "Please add atleast one service";
        }
        break;
      }
      case 5: {
        const defaultCheckedAnnouncement = defaultAnnouncement.filter(
          (it) => it.isChecked
        );
        if (
          announcements &&
          announcements.length == 0 &&
          defaultCheckedAnnouncement.length == 0
        ) {
          errorIt = "Please add atleast one annoucement";
        }
        break;
      }
      default:
        break;
    }

    if (errorIt == "") {
      if (activeStep == steps.length - 1) {
        handleSubmit();
        return;
      }
      setActiveStep(activeStep + 1);
      setError("");
    } else setError(errorIt);
  };

  const handlePreviousStep = () => {
    setLoading(false);
    if (activeStep <= 0) return;
    setActiveStep(activeStep - 1);
    setError("");
  };

  const handleCompanyLogoChange = (e) => {
    const file = e.target.files[0];
    setImageUploadError("");

    if (file.size <= 300 * 1024) {
      var fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = function (e) {
        setCompanyLogo({ file: file, preview: this.result });
      };
      return;
    } else {
      handleLogoRemove();
      setImageUploadError("Please upload image of size less than 300kb");
    }
  };


  const handleLogoRemove = () => {
    setImageUploadError("");
    let imageInput = document.getElementById("company_logo_input");
    imageInput.value = "";
    setCompanyLogo({ file: null, preview: "" });
  };


  const handleUploadGallery = (e) => {
    setGalleryUploadError("");
    const files = e.target.files;
    let updatedGalleryImages = [...galleryImages];
    if (files.length + galleryImages.length > 10){
      setGalleryUploadError("Maximum 10 images can be added")
      return
    }

    const validSizeImages = []
    
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size < 300 * 1024) {
        validSizeImages.push(file)
      } else {
        setGalleryUploadError("Please upload images of size less than 300KB")
      }
    }

    let filesProcessed = 0;
    for (var i = 0; i < validSizeImages.length; i++) {
      const file = validSizeImages[i];
      var fr = new FileReader();
      fr.onload = function (e) {
        const base64String = e.target.result;
        filesProcessed++;
        updatedGalleryImages.push({preview: base64String, file: file});
        if (filesProcessed === validSizeImages.length) {
          setGalleryImages(updatedGalleryImages);
        }
      };
      fr.readAsDataURL(file);
    }
  };

  const handleRemoveGalleryImage = (image) => {
    const updatedGalleryImages = galleryImages.filter(
      (it) => it.preview != image.preview
    );
    setGalleryImages(updatedGalleryImages);
    document.getElementById('gallery_image_input').value= null
  }

  const handleOpenMaps = () => {
    handleOpenLink("https://www.google.com/maps");
    setOpenModal(false);
  };

  const handleServicesCheckbox = (service) => {
    let updatedDefaultServices = [...defaultServicesOffered];
    const itemIndex = updatedDefaultServices.findIndex(
      (item) => item.id === service.id
    );
    if (itemIndex !== -1) {
      updatedDefaultServices[itemIndex].isChecked =
        !updatedDefaultServices[itemIndex].isChecked;
    }
    setDefaultServicesOffered(updatedDefaultServices);
  };

  const handleAnnouncementCheckbox = (announcement) => {
    let updatedDefaultAnnouncement = [...defaultAnnouncement];
    const itemIndex = updatedDefaultAnnouncement.findIndex(
      (item) => item.id === announcement.id
    );
    if (itemIndex !== -1) {
      updatedDefaultAnnouncement[itemIndex].isChecked =
        !updatedDefaultAnnouncement[itemIndex].isChecked;
    }
    setDefaultAnnouncement(updatedDefaultAnnouncement);
  };

  const handleSpecialitiesCheckbox = (speciality) => {
    let updatedDefaultSpecialities = [...defaultSpecialities];
    const itemIndex = updatedDefaultSpecialities.findIndex(
      (item) => item.id === speciality.id
    );
    if (itemIndex !== -1) {
      updatedDefaultSpecialities[itemIndex].isChecked =
        !updatedDefaultSpecialities[itemIndex].isChecked;
    }
    setDefaultSpecialities(updatedDefaultSpecialities);
  };

  const renderModal = () => {
    return (
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.locationModal}
      >
        <Box className={classes.locationModalContent}>
          <Typography style={{ fontWeight: "500", margin: 10, fontSize: 18 }}>
            How to get location link?
          </Typography>
          <Typography>- Please visit google maps</Typography>
          <Typography>- Search for your office location</Typography>
          <Typography>- Paste the url in the field</Typography>
          <Button
            onClick={handleOpenMaps}
            style={{ textTransform: "capitalize", float: "right" }}
          >
            Open maps
          </Button>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      <Header
        text={type == CREATE_NEW ? "Create DigiCard" : "Update DigiCard"}
        callback={() => history.push("/cards")}
        isBlue={true}
      />

      <Container className={classes.root}>
        <CustomizedSteppers activestep={activeStep} steps={steps} />
        {activeStep == 0 && (
          <Box className={classes.stepBox}>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>First Name *</Box>
              <Box display="flex" alignItems="center">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={form.prefix ? form.prefix : "CA"}
                  label=""
                  onChange={handleChange}
                  name="prefix"
                  style={{ width: "28%", marginRight: "2%" }}
                  className={classes.selectInput}
                >
                  <MenuItem value={"Mr."}>Mr.</MenuItem>
                  <MenuItem value={"Ms."}>Ms.</MenuItem>
                  <MenuItem value={"Mrs."}>Mrs.</MenuItem>
                  <MenuItem value={"CA"}>CA</MenuItem>
                  <MenuItem value={"Dr."}>Dr.</MenuItem>
                  <MenuItem value={"Adv."}>Adv.</MenuItem>
                </Select>
                <Input
                  className={classes.input}
                  style={{ width: "70%" }}
                  name="firstName"
                  onChange={handleChange}
                  value={form.firstName ? form.firstName : ""}
                />
              </Box>
            </Box>

            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Last Name</Box>
              <Input
                className={classes.input}
                name="lastName"
                onChange={handleChange}
                value={form.lastName ? form.lastName : ""}
              />
            </Box>

            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Qualification</Box>
              <Input
                className={classes.input}
                name="qualification"
                onChange={handleChange}
                value={form.qualification ? form.qualification : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Mobile *</Box>
              <Box display="flex" alignItems="center">
                <Input
                  className={classes.input}
                  value="+91"
                  disabled
                  style={{ width: "15%", marginRight: "2%" }}
                />
                <Input
                  className={classes.input}
                  name="mobile"
                  onChange={handleChange}
                  style={{ width: "83%" }}
                  value={form.mobile ? form.mobile : ""}
                />
              </Box>
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>
                Alternate mobile (if any)
              </Box>
              <Box display="flex" alignItems="center">
                <Input
                  className={classes.input}
                  value="+91"
                  disabled
                  style={{ width: "15%", marginRight: "2%" }}
                />
                <Input
                  className={classes.input}
                  name="alternateMobile"
                  onChange={handleChange}
                  style={{ width: "83%" }}
                  value={form.alternateMobile ? form.alternateMobile : ""}
                />
              </Box>
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Email Id *</Box>
              <Input
                className={classes.input}
                name="emailId"
                value={form.emailId ? form.emailId : ""}
                onChange={handleChange}
              />
            </Box>
          </Box>
        )}
        {activeStep == 1 && (
          <Box className={classes.stepBox}>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Upload Logo</Box>
              {companyLogo.preview && (
                <Box className={classes.imagePreviewBox}>
                  <img src={companyLogo.preview} className={classes.imagePreview} />
                  <IconButton onClick={handleLogoRemove}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
              <Input
                type="file"
                className={classes.input}
                name="companyLogo"
                onChange={handleCompanyLogoChange}
                id="company_logo_input"
                inputProps={{ accept: "image/*" }}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Enter Company Name *</Box>
              <Input
                className={classes.input}
                name="companyName"
                onChange={handleChange}
                value={form.companyName ? form.companyName : ""}
                disabled={type == UPDATE_EXISTING}
              />
            </Box>
            {imageUploadError != null && (
              <Box className={classes.error} style={{ marginBottom: 7 }}>
                {imageUploadError}
              </Box>
            )}
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>
                Description about company
              </Box>
              <Input
                className={classes.input}
                name="aboutCompany"
                onChange={handleChange}
                multiline
                minRows={2}
                value={form.aboutCompany ? form.aboutCompany : ""}
              />
            </Box>

            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Office Address *</Box>
              <Input
                className={classes.input}
                name="officeAddress"
                onChange={handleChange}
                multiline
                minRows={2}
                value={form.officeAddress ? form.officeAddress : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Pincode *</Box>
              <Input
                className={classes.input}
                name="pincode"
                onChange={handleChange}
                value={form.pincode ? form.pincode : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Alternate Office Address</Box>
              <Input
                className={classes.input}
                name="alternateOfficeAddress"
                onChange={handleChange}
                multiline
                minRows={2}
                value={form.alternateOfficeAddress ? form.alternateOfficeAddress : ""}
              />
            </Box>
            {/* <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>City</Box>
              <Input
                className={classes.input}
                name="city"
                onChange={handleChange}
                value={form.city ? form.city : ""}
              />
            </Box> */}

            <Box className={classes.inputBox}>
              <Box className={classes.locationLinkInfoFlex}>
                {renderModal()}
                <Box className={classes.inputLabel}>
                  Location link (Optional){" "}
                </Box>
                <IconButton
                  onClick={() => setOpenModal(true)}
                  style={{ padding: 0 }}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Box>
              <Input
                className={classes.input}
                name="locationLink"
                onChange={handleChange}
                value={form.locationLink ? form.locationLink : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>UPI Id</Box>
              <Input
                className={classes.input}
                name="upiId"
                onChange={handleChange}
                value={form.upiId ? form.upiId : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Website link</Box>
              <Input
                className={classes.input}
                name="websiteLink"
                onChange={handleChange}
                value={form.websiteLink ? form.websiteLink : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Facebook link</Box>
              <Input
                className={classes.input}
                name="facebookLink"
                onChange={handleChange}
                value={form.facebookLink ? form.facebookLink : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Twitter link (Now X)</Box>
              <Input
                className={classes.input}
                name="twitterLink"
                onChange={handleChange}
                value={form.twitterLink ? form.twitterLink : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Youtube link</Box>
              <Input
                className={classes.input}
                name="youtubeLink"
                onChange={handleChange}
                value={form.youtubeLink ? form.youtubeLink : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Linkedin link</Box>
              <Input
                className={classes.input}
                name="linkedinLink"
                onChange={handleChange}
                value={form.linkedinLink ? form.linkedinLink : ""}
              />
            </Box>
          </Box>
        )}
        {activeStep == 2 && (
          <Box className={classes.stepBox}>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Title</Box>
              <Input
                className={classes.input}
                name="specialityTitle"
                onChange={handleChange}
                value={form.specialityTitle ? form.specialityTitle : ""}
              />
            </Box>

            <Button onClick={handleAddSpeciality} variant="outlined">
              Add Key Client
            </Button>

            {specialities.length > 0 && (
              <Box className={classes.inputBox}>
                <Box className={classes.subInputLabel}>
                  Key Clients added by you:
                </Box>
                {specialities.map((it, index) => {
                  return (
                    <Box key={index} className={classes.serviceBox}>
                      <Box className={classes.flexBox}>
                        <Box className={classes.serviceTitle}>
                          {it.specialityTitle}
                        </Box>
                        <IconButton
                          onClick={() => handleRemoveSpecialities(it)}
                          style={{ padding: 2 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}

            {/* {defaultSpecialities.length > 0 && (
              <Box className={classes.inputBox} style={{ marginTop: 20 }}>
                <Box className={classes.subInputLabel}>
                  Most added specialities:
                </Box>
                {defaultSpecialities.map((it, index) => {
                  return (
                    <Box key={index} className={classes.serviceBox}>
                      <Box className={classes.flexBox}>
                        <Box className={classes.serviceTitle}>
                          {it.specialityTitle}
                        </Box>
                        <BpCheckbox
                          checked={it.isChecked}
                          onChange={() => handleSpecialitiesCheckbox(it)}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )} */}
          </Box>
        )}
        {activeStep == 3 && (
          <Box className={classes.stepBox}>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Upload Gallery Images</Box>
              {/* {previewImgSrc && (
                <Box className={classes.imagePreviewBox}>
                  <img src={previewImgSrc} className={classes.imagePreview} />
                  <IconButton onClick={handleLogoRemove}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )} */}
              <Input
                type="file"
                className={classes.input}
                name="uploadedGalleryImages"
                onChange={handleUploadGallery}
                id="gallery_image_input"
                inputProps={{ accept: "image/*", multiple: true }}
              />
              {galleryUploadError != null && (
              <Box className={classes.error} style={{ marginTop: 7 }}>
                {galleryUploadError}
              </Box>
            )}
              <Grid marginTop={2} container spacing={2}>
                {galleryImages.length > 0 &&
                  galleryImages.map((img, index) => (
                    <Grid key={index} className={classes.galleryImageGrid} item xs={6} sm={4} md={3}>
                      <img
                        key={index}
                        src={img.preview}
                        className={classes.galleryImagePreview}
                      />
                      <IconButton onClick={() => handleRemoveGalleryImage(img)} className={classes.galleryImageCloseButton}><CloseIcon /></IconButton>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Box>
        )}
        {activeStep == 4 && (
          <Box className={classes.stepBox}>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Title *</Box>
              <Input
                className={classes.input}
                name="serviceOfferedTitle"
                onChange={handleChange}
                value={form.serviceOfferedTitle ? form.serviceOfferedTitle : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Description</Box>
              <Input
                className={classes.input}
                name="serviceOfferedDesc"
                onChange={handleChange}
                multiline
                value={form.serviceOfferedDesc ? form.serviceOfferedDesc : ""}
                minRows={2}
              />
            </Box>
            <Button onClick={handleAddService} variant="outlined">
              Add Service
            </Button>

            {servicesOffered.length > 0 && (
              <Box className={classes.inputBox}>
                <Box className={classes.subInputLabel}>
                  Services added by you:
                </Box>
                {servicesOffered.map((service, index) => {
                  return (
                    <Box key={index} className={classes.serviceBox}>
                      <Box className={classes.flexBox}>
                        <Box className={classes.serviceTitle}>
                          {service.title}
                        </Box>
                        <IconButton
                          onClick={() => handleRemoveService(service)}
                          style={{ padding: 2 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <Box
                        style={{ fontWeight: 400 }}
                        className={classes.serviceDesc}
                      >
                        {service.description}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}

            {defaultServicesOffered.length > 0 && (
              <Box className={classes.inputBox} style={{ marginTop: 20 }}>
                <Box className={classes.subInputLabel}>
                  Most added services:
                </Box>
                {defaultServicesOffered.map((service, index) => {
                  return (
                    <Box key={index} className={classes.serviceBox}>
                      <Box className={classes.flexBox}>
                        <Box className={classes.serviceTitle}>
                          {service.title}
                        </Box>
                        <BpCheckbox
                          checked={service.isChecked}
                          onChange={() => handleServicesCheckbox(service)}
                        />
                      </Box>
                      <Box
                        style={{ fontWeight: 400 }}
                        className={classes.serviceDesc}
                      >
                        {service.description}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        )}
        {activeStep == 5 && (
          <Box className={classes.stepBox}>
            <Box className={classes.inputBox}>
              <Box className={classes.flexBox}>
                <Box className={classes.inputLabel}>Announcements *</Box>
              </Box>
              <Input
                className={classes.input}
                name="announcementTitle"
                onChange={handleChange}
                value={form.announcementTitle ? form.announcementTitle : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.flexBox}>
                <Box className={classes.inputLabel}>Link (if any)</Box>
              </Box>
              <Input
                className={classes.input}
                name="announcementLink"
                onChange={handleChange}
                value={form.announcementLink ? form.announcementLink : ""}
              />
            </Box>
            <Button onClick={handleAddAnnouncement} variant="outlined">
              Add announcement
            </Button>

            {announcements.length > 0 && (
              <Box className={classes.inputBox}>
                <Box className={classes.subInputLabel}>
                  Announcements added by you:
                </Box>
                {announcements.map((announcement, index) => {
                  return (
                    <Box key={index} className={classes.serviceBox}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box className={classes.serviceDesc}>
                          {announcement.announcement}
                        </Box>
                        <IconButton
                          style={{ padding: 2 }}
                          onClick={() => handleRemoveAnnouncement(announcement)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <Link href={announcement.link}>
                        {announcement.link != "" && announcement.link}
                      </Link>
                    </Box>
                  );
                })}
              </Box>
            )}

            {defaultAnnouncement.length > 0 && (
              <Box className={classes.inputBox} style={{ marginTop: 20 }}>
                <Box className={classes.subInputLabel}>
                  Most added annoucements:
                </Box>
                {defaultAnnouncement.map((announcement, index) => {
                  return (
                    <Box key={index} className={classes.serviceBox}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box className={classes.serviceDesc}>
                          {announcement.announcement}
                        </Box>
                        <BpCheckbox
                          checked={announcement.isChecked}
                          onChange={() =>
                            handleAnnouncementCheckbox(announcement)
                          }
                        />
                      </Box>
                      <Link
                        style={{ lineHeight: 1.3 }}
                        href={announcement.link}
                      >
                        {announcement.link != "" && announcement.link}
                      </Link>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        )}

        {activeStep == 6 && (
          <Box className={classes.stepBox}>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Testimonial</Box>
              <Input
                className={classes.input}
                name="testimonialDescription"
                onChange={handleChange}
                value={
                  form.testimonialDescription ? form.testimonialDescription : ""
                }
                multiline
                minRows={2}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>Testimonial Author</Box>
              <Input
                className={classes.input}
                name="testimonialAuthor"
                onChange={handleChange}
                value={form.testimonialAuthor ? form.testimonialAuthor : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box className={classes.inputLabel}>
                Testimonial Author Occupation
              </Box>
              <Input
                className={classes.input}
                name="testimonialAuthorOccupation"
                onChange={handleChange}
                value={
                  form.testimonialAuthorOccupation
                    ? form.testimonialAuthorOccupation
                    : ""
                }
              />
            </Box>
          </Box>
        )}

        <Box
          style={{
            position: "sticky",
            bottom: 0,
            paddingBottom: 20,
            backgroundColor: "#F3F4F7",
          }}
        >
          {error != null && (
            <Box className={classes.error} style={{ paddingTop: 10 }}>
              {error}
            </Box>
          )}
          <Box className={classes.flexBox}>
            {activeStep != 0 && (
              <Button
                className={classes.stepButton}
                onClick={handlePreviousStep}
              >
                Back
              </Button>
            )}
            {activeStep < steps.length && (
              <Button
                onClick={() => handleNextStep(activeStep)}
                className={classes.stepButton}
                style={
                  activeStep == steps.length - 1 && !loading
                    ? { backgroundColor: "#4D77FA", color: "white" }
                    : undefined
                }
                disabled={loading}
              >
                {activeStep == steps.length - 1
                  ? type == CREATE_NEW
                    ? "Submit"
                    : "Update Card"
                  : "Save and Next"}
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default CreateDigitalCard;
