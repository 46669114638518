import React from "react";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import CampaignIcon from "@mui/icons-material/Campaign";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";
import PaymentIcon from "@mui/icons-material/Payment";
import { digitalCardStyles } from "../../CreateDigitalCard/DigitalCardStyle";
import { Box, IconButton, Typography } from "@mui/material";
import CollectionsIcon from '@mui/icons-material/Collections';

function Footer({ paymentDetailsExists, galleryViewExists }) {
  const digitalCardClasses = digitalCardStyles();

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Box className={digitalCardClasses.footer}>
      <IconButton
        onClick={() => handleClickScroll("home")}
        className={digitalCardClasses.footerIconFlex}
      >
        <HomeIcon className={digitalCardClasses.footerIcon} />
        <Typography className={digitalCardClasses.footerIconText}>
          Home
        </Typography>
      </IconButton>
      <IconButton
        onClick={() => handleClickScroll("aboutUs")}
        className={digitalCardClasses.footerIconFlex}
      >
        <BusinessIcon className={digitalCardClasses.footerIcon} />
        <Typography className={digitalCardClasses.footerIconText}>
          About us
        </Typography>
      </IconButton>
      <IconButton
        onClick={() => handleClickScroll("services")}
        className={digitalCardClasses.footerIconFlex}
      >
        <MiscellaneousServicesIcon className={digitalCardClasses.footerIcon} />
        <Typography className={digitalCardClasses.footerIconText}>
          Services
        </Typography>
      </IconButton>
      <IconButton
        onClick={() => handleClickScroll("announcement")}
        className={digitalCardClasses.footerIconFlex}
      >
        <CampaignIcon className={digitalCardClasses.footerIcon} />
        <Typography className={digitalCardClasses.footerIconText}>
          Announcement
        </Typography>
      </IconButton>
      {galleryViewExists && (
        <IconButton
        onClick={() => handleClickScroll("gallery")}
        className={digitalCardClasses.footerIconFlex}
      >
        <CollectionsIcon className={digitalCardClasses.footerIcon} />
        <Typography className={digitalCardClasses.footerIconText}>
          Gallery
        </Typography>
      </IconButton>
      )}
      {paymentDetailsExists && (
        <IconButton
          onClick={() => handleClickScroll("payment")}
          className={digitalCardClasses.footerIconFlex}
        >
          <PaymentIcon className={digitalCardClasses.footerIcon} />
          <Typography className={digitalCardClasses.footerIconText}>
            Payment
          </Typography>
        </IconButton>
      )}
    </Box>
  );
}

export default Footer;
