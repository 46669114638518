import { green, purple } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  // palette: {
  //   primary: {
  //       background: "#E9ECEF" ,
  //       main: purple[500],
  //       color: '#343A40',
  //       divider: '#E9ECEF'
  //   },
  //   secondary: {
  //     main: "#495057",
  //     font: "#F8F9FA",
  //     divider: '#DEE2E6'
  //   },
  //   icon: {
  //       background: '#212529'
  //   },
  //   container: {
  //     background: '#E9ECEF80'
  //   },
  //   color: {
  //     link: '#9c27b0'
  //   }
  // },
  // typography: {
  //   "fontFamily": `"Inter", "Helvetica", "Arial", sans-serif`,
  //   // "fontSize": 14,
  //   // "fontWeightLight": 300,
  //   // "fontWeightRegular": 400,
  //   // "fontWeightMedium": 500
  //  }
  typography: {
    fontFamily: `"Inter", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    primary: {
      background: "#F1F1F1",
      main: "#0E4160",
      white: "#FFFFFF",
      black: "#000"
    },
    secondary: {
      main: "#63AE49",
    },
    typography: {
      fontFamily: `"Inter", "Helvetica", "Arial", sans-serif`,
    },
  },
  home: {
    primary: {
      background: "#061144",
      main: "#f3f4f7",
      grey: "#76839c",
      darkGrey: "#77767A",
      figmaBlue: "#1E577A"
    },
    secondary: {
      background: "#f3f4f7",
      blueButtonBg: "#4D77FA",
    },
  },
});
