import React, { useState } from "react";
import { useStyles } from "./Style";
import { VisitingCardStyles } from "../../../Cards/VisitingCard/VisitingCardStyles";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Fade,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import CALogo from "../../../../assets/CA_Logo.png";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { handleOpenLink, handleSystemLinkOpen, isPresent } from "../../../../utils/helper";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import SmsIcon from "@mui/icons-material/Sms";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IosShareIcon from "@mui/icons-material/IosShare";
import WhatsappIconDownload from "../../../../assets/whatsapp.svg";
import gmailIcon from "../../../../assets/Gmail_icon.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { digitalCardStyles } from "../../../Cards/CreateDigitalCard/DigitalCardStyle";

function Home({
  isCardOwner,
  id,
  cardDetail,
  handleContactSave,
}) {
  const classes = useStyles();
  const visitingCardClasses = VisitingCardStyles();
  const digitalCardClasses = digitalCardStyles();
  const history = useHistory();

  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [shareModal, setShareModal] = useState(false);
  const [whatsappShare, setWhatappShare] = useState(false);
  const [whatsappNumberModal, setWhatsappNumberModal] = useState("");

  // const sections = [
  //   { title: "About Us", page: 1 },
  //   { title: "Services Offered", page: 2 },
  //   { title: "Annoucements", page: 3 },
  //   { title: "Payment", page: 4 },
  //   { title: "Contact Details", page: 4 },
  // ];

  const handleWhatsappShare = (selectNumber) => {
    const trimmedWhatsapp = selectNumber.replace(/\s/g, "");
    if (trimmedWhatsapp == "" && trimmedWhatsapp.length == 10) return;
    const text = `Hey, Please find the DigiCard of ${cardDetail.companyName} here: ${window.location.href}. It has all our contact info and services offered for easy access. Let me know if you have any questions!`;
    const encodedText = encodeURIComponent(text);
    handleOpenLink(`https://wa.me/91${trimmedWhatsapp}?text=${encodedText}`);
    setWhatsappNumber("")
  };

  const handleCloseShareModal = () => {
    setShareModal(false);
    setWhatappShare(false);
    setWhatsappNumberModal(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    var el = document.getElementById(`shareVisitingCardLinkCopied`);

    el.style.display = "block";
    setTimeout(() => {
      el.style.display = "none";
    }, 2000);
  };

  const handleModalWhatsappShare = () => {
    // setWhatappShare(true);
    const text = `Hey, Please find the DigiCard of ${cardDetail.companyName} here: ${window.location.href}. It has all our contact info and services offered for easy access. Let me know if you have any questions!`;
    const encodedText = encodeURIComponent(text);
    handleOpenLink(`https://wa.me/?text=${encodedText}`);
  };

  const handleSmsShare = (companyName) => {
    const body = encodeURIComponent(
      `Hey, Please find the DigiCard of ${companyName} here: ${window.location.href}. It has all our contact info and services offered for easy access. Let me know if you have any questions!`
    );
    handleSystemLinkOpen(`sms:?&body=${body}`);
  };

  const handleEmailShare = (companyName) => {
    const subject = encodeURIComponent(`DigiCard of ${companyName}`);
    const body = encodeURIComponent(
      `Hey, Please find the DigiCard of ${companyName} here: ${window.location.href}. It has all our contact info and services offered for easy access. Let me know if you have any questions!`
    );
    handleSystemLinkOpen(`mailto:?subject=${subject}&body=${body}`);
  };

  const handleOpenShareModal = () => {
    setShareModal(true);
  };

  const renderShareModal = () => {
    return (
      <Modal
        aria-labelledby="share-modal-title"
        aria-describedby="share-modal-description"
        open={shareModal}
        onClose={handleCloseShareModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={shareModal}>
          <Box className={visitingCardClasses.shareModal}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography
                id="share-modal-title"
                style={{
                  fontWeight: 500,
                  fontSize: "1.2rem",
                  marginBottom: 20,
                }}
              >
                Share
              </Typography>
              <IconButton
                onClick={() => setShareModal(false)}
                style={{ padding: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Box
              id="share-modal-description"
              className={visitingCardClasses.modelButtonFlex}
            >
              <Box className={classes.shareBtnFlex}>
                <IconButton
                  onClick={handleModalWhatsappShare}
                  className={visitingCardClasses.shareBtn}
                >
                  <img src={WhatsappIconDownload} width={"25"} />
                  {/* <WhatsAppIcon /> */}
                </IconButton>
                <Typography className={visitingCardClasses.shareBtnText}>
                  Whatsapp
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => handleSmsShare(cardDetail.companyName)}
                  className={visitingCardClasses.shareBtn}
                >
                  <SmsIcon style={{ color: "#2096f3" }} />
                </IconButton>
                <Typography className={visitingCardClasses.shareBtnText}>
                  SMS
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => handleEmailShare(cardDetail.companyName)}
                  className={visitingCardClasses.shareBtn}
                >
                  {/* <EmailIcon /> */}
                  <Box style={{ width: 24, height: 24 }}>
                    <img src={gmailIcon} style={{ width: 24 }} />
                  </Box>
                </IconButton>
                <Typography className={visitingCardClasses.shareBtnText}>
                  Email
                </Typography>
              </Box>
            </Box>
            {/* {whatsappShare && (
              <Box style={{ marginTop: 15, width: "100%" }}>
                <Typography style={{ marginTop: 20 }}>
                  Share on whatsapp
                </Typography>

                <Box className={visitingCardClasses.modalShareBox}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleWhatsappShare(whatsappNumberModal)
                            }
                          >
                            <SendIcon style={{ color: "#0E4160" }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography>+91</Typography>
                        </InputAdornment>
                      ),
                      className: classes.shareBoxInput,
                    }}
                    fullWidth
                    value={whatsappNumberModal}
                    onChange={(e) => setWhatsappNumberModal(e.target.value)}
                  />
                </Box>
              </Box>
            )} */}
            <Box style={{ marginTop: 20 }}>
              <Typography className={visitingCardClasses.pageLink}>
                Page Link
              </Typography>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={() => handleCopy()}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  className: visitingCardClasses.pageLinkCopy,
                }}
                fullWidth
                disabled
                value={window.location.href}
              ></TextField>

              <Typography
                id="shareVisitingCardLinkCopied"
                className={visitingCardClasses.linkCopiedTxt}
              >
                Link Copied
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  };

  return (
    <Container maxWidth={"sm"} className={classes.container}>
      {isCardOwner && (
        <Button
          variant="outlined"
          className={visitingCardClasses.editCardBtn}
          onClick={() =>
            history.push(`/card/digital-card/update/${cardDetail._id}`)
          }
        >
          <EditIcon style={{ transform: "scale(0.7)" }} />
          Edit
        </Button>
      )}
      <Button
        variant="outlined"
        className={visitingCardClasses.shareCardBtn}
        onClick={handleOpenShareModal}
      >
        <IosShareIcon style={{ transform: "scale(0.7)" }} />
        Share
      </Button>
      {renderShareModal()}
      <Box id={id} className={classes.detailsFlex}>
        <Box className={classes.companyName}>
          {isPresent(cardDetail, cardDetail.companyName) &&
            cardDetail.companyName}
        </Box>
        {isPresent(cardDetail, cardDetail.companyLogo) ? (
          <Box className={classes.companyLogo}>
            <img  class="companyLogo" alt="Company Logo" src={cardDetail.companyLogo} />
          </Box>
        ) : cardDetail.prefix == "CA" ? (
          <Box className={classes.companyLogo}>
            <img  class="companyLogo" alt="Company Logo" src={CALogo} />
          </Box>
        ) : (
          ""
        )}
        <Box>
          <Box className={classes.person}>
            {isPresent(cardDetail, cardDetail.prefix) && cardDetail.prefix}{" "}
            {isPresent(cardDetail, cardDetail.firstName) &&
              cardDetail.firstName}{" "}
            {isPresent(cardDetail, cardDetail.lastName) && cardDetail.lastName}
          </Box>
          <Box className={classes.qualification}>
            {isPresent(cardDetail, cardDetail.qualification) &&
              `(${cardDetail.qualification})`}
          </Box>
        </Box>

        <Box className={classes.iconContainer}>
          {isPresent(cardDetail, cardDetail.mobile) && (
            <IconButton
              className={classes.iconBox}
              onClick={() => handleSystemLinkOpen(`tel:${cardDetail && cardDetail.mobile}`)}
            >
              <CallIcon style={{ color: "white" }} />
            </IconButton>
          )}
          {isPresent(cardDetail, cardDetail.emailId) && (
            <IconButton
              className={classes.iconBox}
              onClick={() => handleSystemLinkOpen(`mailto:${cardDetail.emailId}`)}
            >
              <EmailIcon style={{ color: "white" }} />
            </IconButton>
          )}
          {isPresent(cardDetail, cardDetail.mobile) && (
            <IconButton
              className={classes.iconBox}
              onClick={() => handleOpenLink(
                `https://api.whatsapp.com/send?phone=91${cardDetail.mobile.substring(
                  4
                )}`
              )}
            >
              <WhatsAppIcon style={{ color: "white" }} />
            </IconButton>
          )}
          {isPresent(cardDetail, cardDetail.locationLink) && (
            <IconButton
              className={classes.iconBox}
              onClick={() => handleOpenLink(cardDetail.locationLink)}
            >
              <LocationOnIcon style={{ color: "white" }} />
            </IconButton>
          )}
          {isPresent(cardDetail, cardDetail.websiteLink) && (
            <IconButton
              className={classes.iconBox}
              onClick={() => handleOpenLink(cardDetail.websiteLink)}
            >
              <LanguageIcon style={{ color: "white" }} />
            </IconButton>
          )}
        </Box>
        <Divider style={{ width: "70%" }} />

        <Box className={visitingCardClasses.contactUsBox}>
          {isPresent(cardDetail, cardDetail.mobile) && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              style={{ width: "100%" }}
            >
              <Box className={classes.contactUsIconBox}>
                <PhoneAndroidOutlinedIcon className={classes.contactUsIcon} />
                <Box>
                  <Box fontWeight={"500"}>Mobile</Box>
                  <Box fontSize={"15px"}>{cardDetail.mobile}</Box>
                  <Box fontSize={"15px"}>
                    {isPresent(cardDetail, cardDetail.alternateMobile) &&
                      cardDetail.alternateMobile}
                  </Box>
                </Box>
              </Box>
              <IconButton onClick={handleContactSave} color="white">
                <PersonAddAlt1Icon
                  style={{ color: "#0E4160" }}
                  fontSize="small"
                />
              </IconButton>
            </Box>
          )}
          {isPresent(cardDetail, cardDetail.emailId) && (
            <Box className={classes.contactUsIconBox}>
              <AlternateEmailOutlinedIcon className={classes.contactUsIcon} />
              <Box>
                <Box fontWeight={"500"}>Email</Box>
                <Box fontSize={"15px"}>{cardDetail.emailId}</Box>
              </Box>
            </Box>
          )}
          {isPresent(cardDetail, cardDetail.officeAddress) && (
            <Box className={classes.contactUsIconBox}>
              <LocationOnOutlinedIcon className={classes.contactUsIcon} />
              <Box>
                <Box fontWeight={"500"}>Office address</Box>
                <Box fontSize={"15px"}>
                  {cardDetail.officeAddress}
                  {cardDetail &&
                    cardDetail.pincode != "" &&
                    " - " + cardDetail.pincode}
                </Box>
                {isPresent(cardDetail, cardDetail.alternateOfficeAddress) && <Box fontSize={"15px"} style={{marginTop: 5}}>
                  {cardDetail.alternateOfficeAddress}
                </Box>}
              </Box>
            </Box>
          )}
        </Box>

        <Box style={{ marginTop: 15, width: "100%" }}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box className={classes.subheading} style={{ marginRight: 10 }}>
              Share on whatsapp
            </Box>
            <WhatsAppIcon style={{ color: "green" }} />
          </Box>

          <Box className={visitingCardClasses.shareBox}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleWhatsappShare(whatsappNumber)}
                    >
                      <SendIcon style={{ color: "#0E4160" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography>+91</Typography>
                  </InputAdornment>
                ),
                className: classes.shareBoxInput,
              }}
              fullWidth
              value={whatsappNumber}
              onChange={(e) => setWhatsappNumber(e.target.value)}
            />
          </Box>
        </Box>

        {/* <Box className={classes.description}>
          <List className={classes.serviceList}>
            {sections.map((service, index) => {
              return (
                <ListItem key={index} className={classes.serviceListItem}>
                  <Box
                    className={classes.serviceListItemText}
                    onClick={() => {
                      handlePageChange(service.page);
                    }}
                  >
                    {service.title}
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Box> */}
      </Box>
      {/* <Box className={classes.bottomDrop} onClick={() => handlePageChange(1)}>
        <Box className={classes.bottomDropText}>About Us</Box>
        <IconButton color="white">
          <KeyboardDoubleArrowDownIcon
            style={{ color: "white", paddingTop: 0 }}
          />
        </IconButton>
      </Box> */}
    </Container>
  );
}

export default Home;
