import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Box,
  Button,
  Container,
  Divider,
  Fade,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import SendIcon from "@mui/icons-material/Send";
import LanguageIcon from "@mui/icons-material/Language";
import IosShareIcon from "@mui/icons-material/IosShare";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import SmsIcon from "@mui/icons-material/Sms";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { useStyles } from "../DigitalCard/Components/Style";
import { VisitingCardStyles } from "./VisitingCardStyles";
import CALogo from "../../../assets/CA_Logo.png";
import { makeVCard } from "../../../utils/generateVcard";
import { getVisitingCardView } from "../../../api/VisitingCard";
import {
  handleOpenLink,
  handleSystemLinkOpen,
  isPresent,
} from "../../../utils/helper";
import { showSnackbar } from "../../../redux/actions/auth";
import CompanyLogo from "../../../assets/Logo_Synclink.svg";
import WhatsappIconDownload from "../../../assets/whatsapp.svg";
import gmailIcon from "../../../assets/Gmail_icon.svg";


function VisitingCard() {
  const classes = useStyles();
  const visitingCardClasses = VisitingCardStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [cardExpired, setCardExpired] = useState(false);
  const [cardDetail, setCardDetail] = useState({});
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [isCardOwner, setIsCardOwner] = useState(false);

  const [shareModal, setShareModal] = useState(false);

  const { uniqueId } = useParams();

  const isAndroid = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.includes("android");
  };

  const handleWhatsappShare = (selectNumber) => {
    const trimmedWhatsapp = selectNumber.replace(/\s/g, "");
    if (trimmedWhatsapp == "" && trimmedWhatsapp.length == 10) return;
    const text = `Hey, Please find the E-Visiting Card of ${cardDetail.companyName} here: ${window.location.href}. It has all our contact info and services offered for easy access. Let me know if you have any questions!`;
    const encodedText = encodeURIComponent(text);
    handleOpenLink(`https://wa.me/91${selectNumber}?text=${encodedText}`);
    setWhatsappNumber("");
  };

  useEffect(() => {
    if (uniqueId != undefined) {
      getVisitingCardView(uniqueId)
        .then((res) => {
          const cardDetail = res.data.card;
          setCardDetail(cardDetail);
          setIsCardOwner(res.data.isOwner);
          const date = new Date().getTime();
          const validTill = new Date(cardDetail.validTill).getTime();
          if (date > validTill) {
            setCardExpired(true);
          }
        })
        .catch((err) => history.push("/"));
    }
  }, [uniqueId]);

  const handleContactSave = () => {
    const name = `${cardDetail.firstName} ${cardDetail.lastName}`;
    makeVCard(
      name,
      cardDetail.companyName,
      cardDetail.mobile,
      cardDetail.emailId
    );
    dispatch(
      showSnackbar(
        isAndroid
          ? "Please click on the downloaded file to save contact details"
          : "Please click on create contact to Save",
        "success"
      )
    );
  };

  const handleOpenShareModal = () => {
    setShareModal(true);
  };

  const handleCloseShareModal = () => {
    setShareModal(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    var el = document.getElementById(`shareVisitingCardLinkCopied`);

    el.style.display = "block";
    setTimeout(() => {
      el.style.display = "none";
    }, 2000);
  };

  const handleModalWhatsappShare = () => {
    const text = `Hey, Please find the E-Visiting Card of ${cardDetail.companyName} here: ${window.location.href}. It has all our contact info and services offered for easy access. Let me know if you have any questions!`;
    const encodedText = encodeURIComponent(text);
    handleSystemLinkOpen(`https://wa.me/?text=${encodedText}`);
  };

  const handleSmsShare = () => {
    const body = encodeURIComponent(
      `Hey, Please find the E-Visiting Card of ${cardDetail.companyName} here: ${window.location.href}. It has all our contact info and services offered for easy access. Let me know if you have any questions!`
    );
    handleSystemLinkOpen(`sms:?&body=${body}`);
  };

  const handleEmailShare = () => {
    const subject = encodeURIComponent(
      `E-Visiting Card of ${cardDetail.companyName}`
    );
    const body = encodeURIComponent(
      `Hey, Please find the E-Visiting Card of ${cardDetail.companyName} here: ${window.location.href}. It has all our contact info and services offered for easy access. Let me know if you have any questions!`
    );
    handleSystemLinkOpen(`mailto:?subject=${subject}&body=${body}`);
  };

  const renderShareModal = () => {
    return (
      <Modal
        aria-labelledby="share-modal-title"
        aria-describedby="share-modal-description"
        open={shareModal}
        onClose={handleCloseShareModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={shareModal}>
          <Box className={visitingCardClasses.shareModal}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography
                id="share-modal-title"
                style={{
                  fontWeight: 500,
                  fontSize: "1.2rem",
                  marginBottom: 20,
                }}
              >
                Share
              </Typography>
              <IconButton
                onClick={() => setShareModal(false)}
                style={{ padding: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Box
              id="share-modal-description"
              className={visitingCardClasses.modelButtonFlex}
            >
              <Box className={classes.shareBtnFlex}>
                <IconButton
                  onClick={handleModalWhatsappShare}
                  className={visitingCardClasses.shareBtn}
                >
                  <img src={WhatsappIconDownload} width={"25"} />
                  {/* <WhatsAppIcon /> */}
                </IconButton>
                <Typography className={visitingCardClasses.shareBtnText}>
                  Whatsapp
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={handleSmsShare}
                  className={visitingCardClasses.shareBtn}
                >
                  <SmsIcon style={{ color: "#2096f3" }} />
                </IconButton>
                <Typography className={visitingCardClasses.shareBtnText}>
                  SMS
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={handleEmailShare}
                  className={visitingCardClasses.shareBtn}
                >
                  <Box style={{ width: 24, height: 24 }}>
                    <img src={gmailIcon} style={{ width: 24 }} />
                  </Box>
                </IconButton>
                <Typography className={visitingCardClasses.shareBtnText}>
                  Email
                </Typography>
              </Box>
            </Box>
            <Box style={{ marginTop: 20 }}>
              <Typography className={visitingCardClasses.pageLink}>
                Page Link
              </Typography>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={() => handleCopy()}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  className: visitingCardClasses.pageLinkCopy,
                }}
                fullWidth
                disabled
                value={window.location.href}
              ></TextField>
              <Typography
                id="shareVisitingCardLinkCopied"
                className={visitingCardClasses.linkCopiedTxt}
              >
                Link Copied
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  };

  return (
    <Container maxWidth={"sm"} className={classes.container}>
      {cardExpired && (
        <Box className={classes.expiredTextBox}>
          <Box className={classes.expiredText}>
            Your card expired, please contact us at support@synclink.in
          </Box>
        </Box>
      )}
      <Box
        className={classes.detailsFlex}
        style={cardExpired ? { filter: "blur(5px)" } : undefined}
      >
        {isCardOwner && (
          <Button
            variant="outlined"
            className={visitingCardClasses.editCardBtn}
            onClick={() =>
              handleOpenLink(
                `https://synclink.in/card/visiting-card/update/${cardDetail._id}`
              )
            }
          >
            <EditIcon style={{ transform: "scale(0.7)" }} />
            Edit
          </Button>
        )}
        <Button
          variant="outlined"
          className={visitingCardClasses.shareCardBtn}
          onClick={handleOpenShareModal}
        >
          <IosShareIcon style={{ transform: "scale(0.7)" }} />
          Share
        </Button>
        {renderShareModal()}
        <Box className={visitingCardClasses.companyName}>
          {isPresent(cardDetail, cardDetail.companyName) &&
            cardDetail.companyName}
        </Box>
        {isPresent(cardDetail, cardDetail.companyLogo) ? (
          <Box className={classes.companyLogo}>
            <img
              class="companyLogo"
              alt="Company Logo"
              src={cardDetail.companyLogo}
            />
          </Box>
        ) : cardDetail.prefix == "CA" ? (
          <Box className={classes.companyLogo}>
            <img class="companyLogo" alt="Company Logo" src={CALogo} />
          </Box>
        ) : (
          ""
        )}
        <Box>
          <Box className={classes.person}>
            {isPresent(cardDetail, cardDetail.prefix) && cardDetail.prefix}{" "}
            {isPresent(cardDetail, cardDetail.firstName) &&
              cardDetail.firstName}{" "}
            {isPresent(cardDetail, cardDetail.lastName) && cardDetail.lastName}
          </Box>
          <Box className={classes.qualification}>
            {isPresent(cardDetail, cardDetail.qualification) &&
              `(${cardDetail.qualification})`}
          </Box>
        </Box>
        <Box className={classes.iconContainer}>
          {isPresent(cardDetail, cardDetail.mobile) && (
            <IconButton
              className={classes.iconBox}
              onClick={() =>
                handleSystemLinkOpen(`tel:${cardDetail && cardDetail.mobile}`)
              }
            >
              <CallIcon style={{ color: "white" }} />
            </IconButton>
          )}
          {isPresent(cardDetail, cardDetail.emailId) && (
            <IconButton
              className={classes.iconBox}
              onClick={() =>
                handleSystemLinkOpen(`mailto:${cardDetail.emailId}`)
              }
            >
              <EmailIcon style={{ color: "white" }} />
            </IconButton>
          )}
          {isPresent(cardDetail, cardDetail.mobile) && (
            <IconButton
              className={classes.iconBox}
              onClick={() =>
                handleOpenLink(
                  `https://api.whatsapp.com/send?phone=91${cardDetail.mobile.substring(
                    4
                  )}`
                )
              }
            >
              <WhatsAppIcon style={{ color: "white" }} />
            </IconButton>
          )}
          {isPresent(cardDetail, cardDetail.locationLink) && (
            <IconButton
              className={classes.iconBox}
              onClick={() => handleOpenLink(cardDetail.locationLink)}
            >
              <LocationOnIcon style={{ color: "white" }} />
            </IconButton>
          )}
          {isPresent(cardDetail, cardDetail.websiteLink) && (
            <IconButton
              className={classes.iconBox}
              onClick={() => handleOpenLink(cardDetail.websiteLink)}
            >
              <LanguageIcon style={{ color: "white" }} />
            </IconButton>
          )}
        </Box>
        <Divider style={{ width: "70%" }} />

        <Box className={visitingCardClasses.contactUsBox}>
          {isPresent(cardDetail, cardDetail.mobile) && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              style={{ width: "100%" }}
            >
              <Box className={classes.contactUsIconBox}>
                <PhoneAndroidOutlinedIcon className={classes.contactUsIcon} />
                <Box>
                  <Box fontWeight={"500"}>Mobile</Box>
                  <Box fontSize={"15px"}>{cardDetail.mobile}</Box>
                  <Box fontSize={"15px"}>
                    {isPresent(cardDetail, cardDetail.alternateMobile) &&
                      cardDetail.alternateMobile}
                  </Box>
                </Box>
              </Box>
              <IconButton onClick={handleContactSave}>
                <PersonAddAlt1Icon
                  style={{ color: "#0E4160" }}
                  fontSize="small"
                />
              </IconButton>
            </Box>
          )}
          {isPresent(cardDetail, cardDetail.emailId) && (
            <Box className={classes.contactUsIconBox}>
              <AlternateEmailOutlinedIcon className={classes.contactUsIcon} />
              <Box>
                <Box fontWeight={"500"}>Email</Box>
                <Box fontSize={"15px"}>{cardDetail.emailId}</Box>
              </Box>
            </Box>
          )}
          {isPresent(cardDetail, cardDetail.officeAddress) && (
            <Box className={classes.contactUsIconBox}>
              <LocationOnOutlinedIcon className={classes.contactUsIcon} />
              <Box>
                <Box fontWeight={"500"}>Office address</Box>
                <Box fontSize={"15px"}>
                  {cardDetail.officeAddress}
                  {cardDetail &&
                    cardDetail.pincode != "" &&
                    " - " + cardDetail.pincode}
                </Box>
                <Box fontSize={"15px"} style={{marginTop: 5}}>
                  {cardDetail.alternateOfficeAddress}
                </Box>
              </Box>
            </Box>
          )}
        </Box>


        <Box style={{ marginTop: 15, width: "100%" }}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box className={classes.subheading} style={{ marginRight: 10 }}>
              Share on whatsapp
            </Box>
            <WhatsAppIcon style={{ color: "green" }} />
          </Box>

          <Box className={visitingCardClasses.shareBox}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleWhatsappShare(whatsappNumber)}
                    >
                      <SendIcon style={{ color: "#0E4160" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography>+91</Typography>
                  </InputAdornment>
                ),
                className: classes.shareBoxInput,
              }}
              fullWidth
              value={whatsappNumber}
              onChange={(e) => setWhatsappNumber(e.target.value)}
            />
          </Box>
        </Box>

        <Box className={classes.footer}>
          <Link href="https://synclink.in">
            <img
              style={{ maxWidth: "150px", marginBottom: 10 }}
              src={CompanyLogo}
            />
          </Link>
          {/* <Button
            onClick={() => history.push("/")}
            className={visitingCardClasses.footerHeading}
          >
            Create your digital card
          </Button> */}
          <Box className={classes.copyrightInfo}>Copyright &#169; 2024</Box>
        </Box>
      </Box>
    </Container>
  );
}

export default VisitingCard;
