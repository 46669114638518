import React, { useEffect, useState } from "react";
import { useStyles } from "./HomePageStyle";
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { connect, useDispatch } from "react-redux";
import { loadUser } from "../../redux/actions/auth";
import { LOGOUT } from "../../redux/const/actionsTypes";
import CompanyLogo from '../../assets/Logo_Synclink.svg';
import frame from '../../assets/illustration.png'


import Steps from "./Steps";
import Footer from "./Footer/Footer";

function HomePage(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (props.auth.authData) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [props.auth]);

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  const handleLogOut = (e) => {
    e.preventDefault();
    dispatch({ type: LOGOUT });
  };

  return (
    <Container className={classes.container}>
      <Box className={classes.centerFlex}>
        <Box className={classes.topCenterFlex}>
          <Box className={classes.header}>      
            <Box className={classes.companyLogo}>
            <img src={CompanyLogo} />
            </Box>
            {authenticated ? (
              <Box>
                <Button 
                 className={classes.loginBtn} onClick={handleLogOut}>
                  Logout
                </Button>
                {/* <Box className={classes.companyLogo}>{props.auth.authData && props.auth.authData.user.name}</Box> */}
              </Box>
            ) : (
              <Button
                className={classes.loginBtn}
                onClick={() => history.push("/login")}
              >
                Login
              </Button>
            )}
          </Box>
          <h1 className={classes.heading}>
            Go Digital with your Professional Identity
          </h1>
          <Box
            className={classes.description}
            style={{ fontWeight: 500, marginBottom: 10 }}
          >
            Say Goodbye to <span style={{color: '#26c736'}}>Paper</span> Business Cards
          </Box>
          <h2 className={classes.description}>
              Digital Card is a cost-effective, environmentally friendly, and adaptable to different contexts, enhancing your networking capabilities in today's digital landscape.
          {/* A digital visiting card is a eco-friendly alternative of a traditional business card.  */}
          {/* It typically includes essential contact information, company details, and clickable links, providing a convenient way for online visitors to access and save your business information. */}
          {/* {' '}It enhances networking and engagement in the digital space. */}
          </h2>
          {/* <img className={classes.sampleImg} src={CALogo} alt="illustration"/> */}
          {/* <Box className={classes.subheading}>
          Cards designed by Professionals for the Professionals
        </Box> */}
          <Button
            onClick={() => history.push("/cards")}
            className={classes.createCardBtn}
          >
            Get your card
          </Button>
        </Box>
        <Box className={classes.illustrationBox}>
        <img src={frame} className={classes.illustration}/>
          {/* <Box className={classes.illustration}></Box> */}
        </Box>
      </Box>
      <Box className={classes.primaryBox}>
        <Box className={classes.contentBox}>
          <Box className={classes.contentHeading}>
            Introducing Personalised Digital Card solution
          </Box>
          <Box className={classes.contentDescription}>
          It typically includes essential contact information, company details, and clickable links, providing a convenient way for online visitors to access and save your business information.
            {/* In today's fast-paced digital world, traditional paper business
            cards are becoming a thing of the past. Enter our innovative Digital
            Visiting Card solution – the modern way for professionals like you
            to make a lasting impression in the digital realm. */}
          </Box>
        </Box>

        <Box className={classes.contentBox}>
          <Box className={classes.contentHeading}>Features and Benefits</Box>
          <Box className={classes.contentDescription}>
            <span className={classes.contentDescriptionHeading}>
              Streamline Networking:{" "}
            </span>
            Easily share your contact information with clients and colleagues,
            anytime, anywhere.
          </Box>
          <Box className={classes.contentDescription}>
            <span className={classes.contentDescriptionHeading}>
              Stay Connected:{" "}
            </span>
            Update your information instantly, ensuring that your contacts
            always have the latest details.
          </Box>
          <Box className={classes.contentDescription}>
            <span className={classes.contentDescriptionHeading}>
              Customizable Design:{" "}
            </span>
            Showcase your unique personality and branding with customizable
            design templates and branding options.
          </Box>
          <Box className={classes.contentDescription}>
            <span className={classes.contentDescriptionHeading}>
              Environmentally Friendly:{" "}
            </span>
            Reduce paper waste and contribute to a sustainable future with our
            eco-friendly solution.
          </Box>
        </Box>

        <Box className={classes.pricingBox}>
          <Box className={classes.pricingBoxHeading}>E-Visiting Card</Box>
          <Box className={classes.pricingBoxPrice}>Rs. 199 / year</Box>
          <List className={classes.pricingBoxList}>
            <ListItem className={classes.pricingBoxListItem}>
              Unlimited card share
            </ListItem>
            <ListItem className={classes.pricingBoxListItem}>
              Update your information in real time
            </ListItem>
            <ListItem className={classes.pricingBoxListItem}>
              Share with anyone on Whatsapp
            </ListItem>
            <ListItem className={classes.pricingBoxListItem}>
              Save Contact directly to Contacts
            </ListItem>
            <ListItem className={classes.pricingBoxListItem}>
              Easy tracking of Views
            </ListItem>
            <ListItem className={classes.pricingBoxListItem}>
              Email support
            </ListItem>
          </List>
        </Box>

        <Box className={classes.pricingBox} style={{ borderColor: "#0057FF" }}>
          <Box
            className={classes.pricingBoxHeading}
            style={{ backgroundColor: "#0057FF" }}
          >
            DigiCard
          </Box>
          <Box className={classes.pricingBoxPrice}>Rs. 599 / year</Box>
          <List className={classes.pricingBoxList}>
            <ListItem className={classes.pricingBoxListItem}>
              Unlimited card share
            </ListItem>
            <ListItem className={classes.pricingBoxListItem}>
              Update your information in real time
            </ListItem>
            <ListItem className={classes.pricingBoxListItem}>
              Share with anyone on Whatsapp
            </ListItem>
            <ListItem className={classes.pricingBoxListItem}>
              Save Contact directly to Contacts
            </ListItem>
            <ListItem className={classes.pricingBoxListItem}>
              Easy tracking of Views
            </ListItem>
            <ListItem className={classes.pricingBoxListItem}>
              Enhanced card with advanced customization options
            </ListItem>
            <ListItem className={classes.pricingBoxListItem}>
              Announcements, Services and Payment sections
            </ListItem>
            <ListItem className={classes.pricingBoxListItem}>
              Priority customer support
            </ListItem>
          </List>
        </Box>
        <Steps />
          <Box className={classes.contentBox} style={{marginTop: 30, width: '98%'}}>
          <Box className={classes.contentDescription}>
            Whether you're a Chartered Accountant, doctor, or any other working professional, our digital cards are tailored to meet your needs and elevate your professional image.
          </Box>
        </Box>
        <Button
            onClick={() => history.push("/cards")}
            className={classes.createCardSecondaryBtn}
          >
            Get your card
          </Button>
      </Box>
      <Footer />
      {/*         Let you clients know that you are aware of all updates and get an edge over your competitors.
       */}
    </Container>
  );
}

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(HomePage);
