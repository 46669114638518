import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    padding: 30,
    fontFamily: "Inter",
    backgroundColor: theme.home.secondary.background,
    display: "flex",
    flexDirection: "column",
    paddingTop: 110
    // alignItems: 'center'
  },
  heading: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: "700",
    marginBottom: 20,
  },
  card: {
    backgroundColor: theme.palette.primary.white,
    borderRadius: 8,
    width: "100%",
    padding: 15,
    paddingBottom: 10,
    marginBottom: 15,
  },
  cardTitle: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: "500",
    marginBottom: 5,
    lineHeight: 1,
  },
  cardSubTitle: {
    color: theme.home.primary.grey,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "500",
    lineHeight: 1.4,
  },
  cardType: {
    color: theme.home.primary.grey,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "400",
    lineHeight: 1.4,
    color: "#989da6",
  },
  divider: {
    marginTop: 10,
    borderStyle: "dashed",
  },
  flexIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    // marginTop: 5
  },
  flexActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 5,
  },
  actionButtonBorder: {
    color: theme.home.primary.figmaBlue,
    fontSize: theme.typography.pxToRem(16),
    width: "50%",
    textAlign: "center",
    border: 0,
    background: "transparent",
    borderRight: `1px dashed rgba(0, 0, 0, 0.12)`,
  },
  actionButton: {
    color: theme.home.primary.figmaBlue,
    fontSize: theme.typography.pxToRem(16),
    width: "50%",
    textAlign: "center",
    border: 0,
    background: "transparent",
  },
  cardName: {
    color: theme.home.primary.grey,
    fontSize: theme.typography.pxToRem(15),
    display: "flex",
    alignItems: "center",
  },
  createCardBtn: {
    backgroundColor: theme.home.primary.figmaBlue,
    width: 200,
    color: theme.palette.primary.white,
    padding: '10px 0px 10px 0px',
    marginTop: 20,
    border: 0,
    marginLeft: 0,
    borderRadius: 8
  },
  linkCopiedTxt: {
    fontSize: theme.typography.pxToRem(12),
    position: "absolute",
    left: 0,
    opacity: 0.7,
    display: "none",
  },
  iconButton: {
    padding: 0,
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '80%',
    padding: 20,
    background: theme.palette.primary.white,
    borderRadius: 10,
    boxShadow: 2
  },
  modelButtonFlex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 10
  },
  modelButton: {
    border: 0,
    margin: 10,
    width: '100%',
    fontSize: theme.typography.pxToRem(20),
    border: `1px solid ${theme.home.primary.figmaBlue}`,
    background: 'transparent',
    color: theme.home.primary.figmaBlue,
    padding: 10,
    borderRadius: 7,
    marginLeft: 0,
    textAlign: 'left'
  },

  // Share Modal design
  shareModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90%',
    padding: 20,
    background: theme.palette.primary.white,
    borderRadius: 10,
    boxShadow: 2,
    minHeight: '30%'
  },
  subheading: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
    fontFamily: "Inter",
    color: theme.palette.secondary.main,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    marginTop: 15
  },
  shareModalDesc: {
    fontFamily: "Inter",
    color: theme.palette.primary.main,
  },
  shareBox :{
    marginTop: 20,
    // padding: 15,
    // paddingTop: 0
  },
  shareBoxInput: {
    background: theme.palette.primary.white,
    // border: 0,
    outline: 0,
    borderRadius: 10,
    '& fieldset': {
      // border: 'none',
      outline: 'none',
      borderRadius: 10
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${theme.palette.primary.main} !important`
    }
  },
  cancelShareButton: {
    border: `1px solid ${theme.palette.secondary.main}`,
    background: theme.palette.primary.white,
    padding: '5px 15px',
    color: theme.palette.secondary.main,
    borderRadius: 10,
    width: '40%',
    textAlign: 'center',
  },
  sendShareButton: {
    border: `1px solid ${theme.palette.secondary.main}`,
    background: theme.palette.secondary.main,
    padding: '5px 15px',
    color: theme.palette.primary.white,
    borderRadius: 10,
    width: '40%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  // Overall insights
  overallInsightsHeading: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(18),
    marginTop: 10
  },
  overallInsights: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20
  },
  overallInsightBox: {
    width: '47%',
    background: theme.palette.primary.white,
    height: 150,
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  insightIcon: {
    color: theme.home.primary.figmaBlue,
    backgroundColor: '#f8f9fa',
    padding: 15,
    borderRadius: '50%',
    height: 45,
    width: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  insightIconSubheading: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 500
  },
  renewalMessage: {
    textAlign: 'center',
    marginTop: 20
  }

}));

export const createCardStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    padding: 30,
    fontFamily: "Inter",
    backgroundColor: theme.home.secondary.background,
    display: "flex",
    flexDirection: "column",
    paddingTop: 110
    // alignItems: 'center'
  },
  heading: {
    // textAlign: 'left',
    // textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(26),
    fontWeight: "700",
    margin: 10,
  },
  desc: {
    margin: 10,
    marginBottom: 20,
    marginTop: 0,
  },
  inputBox: {
    margin: 10,
    width: "95%",
  },
  inputLabel: {
    color: "#535d6e",
    fontWeight: "500",
    marginBottom: 10,
    // letterSpacing: 1,
    fontSize: theme.typography.pxToRem(17),
  },
  input: {
    width: "100%",
    // border: '1px solid black',
    padding: "5px 10px",
    backgroundColor: theme.palette.primary.white,
    borderRadius: 2,
    
    "&.Mui-focused": {
      borderBottom: "1px solid #0E4160 !important",
    },
    "&.MuiInput-root::after": {
      borderBottom: "1px solid #0E4160 !important",
    },
  },
  imagePreviewBox: {
    display: 'flex',
    alignItems: 'center'
  },
  imagePreview: {
    maxWidth: 150,
    maxHeight: 150,
    margin: 10
  },
  selectInput: {
    width: "100%",
    backgroundColor: theme.palette.primary.white,
    outline: 'none',
    padding: 0,
    height: 43,
    '&.MuiSelect-select':{
      padding: 0,
       borderTopRightRadius: '0',
      borderBottomRightRadius: '0'
    },
    '& fieldset': {
      border: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      padding: 2,
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
      
    } 
  },
  createCardBtn: {
    // textTransform: 'uppercase',
    backgroundColor: theme.home.primary.figmaBlue,
    "&:hover": {
      backgroundColor: theme.home.primary.figmaBlue,
    },
    width: 200,
    alignSelf: "center",
    margin: 20,
    color: theme.palette.primary.white,
    letterSpacing: 1,
    padding: 10,
    marginTop: 50,
    border: 0,
  },
  error: {
    color: "red",
    textAlign: "center",
  },
  locationLinkInfoFlex: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    position: 'relative'
  },
  locationModal: {
    height: '100vh',
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center'
  },
  locationModalContent: {
    background: 'white',
    width: '80%',
    borderRadius: 10,
    boxShadow: 2,
    padding: 10
  }
}));
