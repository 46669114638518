import React, { useEffect, useState } from "react";
import { createCardStyles } from "./CardsStyle";
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  Input,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import { Label } from "@mui/icons-material";
import { styled } from "@mui/styles";
import {
  GENERATE_VISITING_CARD_URL,
  generateVisitingCard,
  getVisitingCardPrefilledData,
  getVistingCardDetail,
  updateVisitingCard
} from "../../../api/VisitingCard";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../redux/actions/auth";
import {
  CREATE_NEW_VISITING_CARD,
  UPDATE_EXISITING_VISITING_CARD,
} from "../../../redux/const/types";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { handleOpenLink } from "../../../utils/helper";
import CALogo from "../../../assets/CA_Logo.png";
import Header from "../../Homepage/Header/Header";

function CreateCard({ type }) {
  const classes = createCardStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { cardId } = useParams();

  const InitState = {
    companyName: "",
    companyLogo: "",
    prefix: "CA",
    firstName: "",
    lastName: "",
    qualification: "",
    mobile: "",
    alternateMobile: "",
    emailId: "",
    officeAddress: "",
    city: "",
    pincode: "",
    alternateOfficeAddress: "",
    locationLink: "",
    websiteLink: "",
    uploadedCompanyLogo: "",
  };

  const [form, setForm] = useState(InitState);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [companyLogo, setCompanyLogo] = useState({
    file: null,
    preview: CALogo
  })
  const [imageUploadError, setImageUploadError] = useState("");

  const handleChange = (e) => {
    if (e.target.name == "prefix") {
      if (e.target.value == "CA") {
        setCompanyLogo({
          file: null,
          preview: CALogo
        })
      } else {
        handleLogoRemove()
      }
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0)
    if (type == CREATE_NEW_VISITING_CARD) {
      getVisitingCardPrefilledData()
        .then((res) => {
          if (res.data) {
            const updatedForm = {
              ...form,
              ...res.data,
            };
            setForm(updatedForm);
          }
        })
        .catch((err) => {});
    } else if (type == UPDATE_EXISITING_VISITING_CARD && cardId != undefined) {
      getVistingCardDetail(cardId)
        .then((res) => {
          console.log(res.data)
          setForm(res.data);
          if (res.data.companyLogo != ""){
            setCompanyLogo({
              file: null,
              preview: res.data.companyLogo
            })
          } else {
            if (res.data.prefix == "CA"){
              setCompanyLogo({
                file: null,
                preview: CALogo
              })
            } else {
              setCompanyLogo({
                file: null,
                preview: ""
              })
            }
          }
        })
        .catch((err) => {
          dispatch(showSnackbar("Something went wrong", "error", 4000));
        });
    }
  }, [cardId]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let uploadedCompanyLogo = ""
    let existingCompanyLogo = ""

    if (companyLogo.file != null){
      uploadedCompanyLogo = companyLogo.file
    } else {
      existingCompanyLogo = companyLogo.preview == CALogo ? "" : companyLogo.preview
    }

    const data = {
      ...form,
      companyLogo: existingCompanyLogo,
      uploadedCompanyLogo: uploadedCompanyLogo
    }

    if (type == CREATE_NEW_VISITING_CARD) {
      generateVisitingCard(data)
        .then((res) => {
          if (res.status == 200) {
            dispatch(showSnackbar("Card generated successfully", "success", "3000"));
            setTimeout(() => {
              history.push(`/visiting-card/${res.data.uniqueId}`);
            }, 1500);
          } else {
            setLoading(false);
            setError(res.response.data.message);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status == 400) {
            setError(err.response.data.message);
            setLoading(false);
          } else {
            setError("Something went wrong, please try again");
            setLoading(false);
          }
        });
    } else if (type == UPDATE_EXISITING_VISITING_CARD) {
      updateVisitingCard(data, cardId)
        .then((res) => {
          if (res.status == 200) {
            dispatch(
              showSnackbar("Card updated successfully", "success", 5000)
            );
            setTimeout(() => {
              history.push("/cards");
            }, 1500);
          } else {
            setError(res.response.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status == 400) {
            setError(err.response.data.message);
            setLoading(false);
          } else {
            setError("Something went wrong, please try again");
            setLoading(false);
          }
        });
    }
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setImageUploadError("");
    if (file.size <= 300 * 1024) {
      var fr = new FileReader();
      fr.readAsDataURL(e.target.files[0]);
      fr.onload = function (e) {
        setCompanyLogo({ file: file, preview: this.result });
      };
      return;
    } else {
      handleLogoRemove();
      setImageUploadError("Please upload image of size less than 300kb");
    }
  };

  const handleLogoRemove = () => {
    setImageUploadError("");
    let imageInput = document.getElementById("image_input");
    imageInput.value = "";
    setCompanyLogo({ file: null, preview: "" });  
  };

  const handleOpenMaps = () => {
    handleOpenLink("https://www.google.com/maps");
    setOpenModal(false);
  };

  const renderModal = () => {
    return (
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.locationModal}
      >
        <Box className={classes.locationModalContent}>
          <Typography style={{ fontWeight: "500", margin: 10, fontSize: 18 }}>
            How to get location link?
          </Typography>
          <Typography>- Please visit google maps</Typography>
          <Typography>- Search for your office location</Typography>
          <Typography>- Paste the url in the field</Typography>
          <Button
            onClick={handleOpenMaps}
            style={{ textTransform: "capitalize", float: "right" }}
          >
            Open maps
          </Button>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      <Header
        text={
          type == CREATE_NEW_VISITING_CARD
            ? "Create E-Visiting Card"
            : "Update E-Visiting Card"
        }
        callback={() => history.push("/cards")}
        isBlue={true}
      />
      <Container className={classes.root}>
        {/* <Box className={classes.heading}>
        {type == CREATE_NEW_VISITING_CARD
          ? "Create Visiting Card"
          : "Update Visiting Card"}
      </Box> */}
        {/* <Box className={classes.desc}>Please fill the below details</Box> */}
        <Box className={classes.inputBox}>
          <Box className={classes.inputLabel}>Enter Company Name *</Box>
          <Input
            className={classes.input}
            name="companyName"
            onChange={handleChange}
            value={form.companyName ? form.companyName : ""}
            disabled={type == UPDATE_EXISITING_VISITING_CARD}
          />
        </Box>
        <Box className={classes.inputBox}>
          <Box className={classes.inputLabel}>Upload Logo</Box>
          {companyLogo.preview && (
            <Box className={classes.imagePreviewBox}>
              <img
                id="image_preview"
                src={companyLogo.preview}
                className={classes.imagePreview}
              />
              <IconButton onClick={handleLogoRemove}>
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
          <Input
            type="file"
            className={classes.input}
            name="companyLogo"
            onChange={handlePhotoChange}
            id="image_input"
            inputProps={{ accept: "image/*" }}
          />
        </Box>
        {imageUploadError != null && (
          <Box className={classes.error}>{imageUploadError}</Box>
        )}
        <Box className={classes.inputBox}>
          <Box className={classes.inputLabel}>First Name *</Box>
          <Box display='flex' alignItems="center">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={form.prefix ? form.prefix : "CA"}
            label=""
            onChange={handleChange}
            name="prefix"
            style={{ width: "28%", marginRight: "2%" }}
            className={classes.selectInput}
          >
            <MenuItem value={"Mr."}>Mr.</MenuItem>
            <MenuItem value={"Ms."}>Ms.</MenuItem>
            <MenuItem value={"Mrs."}>Mrs.</MenuItem>
            <MenuItem value={"CA"}>CA</MenuItem>
            <MenuItem value={"Dr."}>Dr.</MenuItem>
            <MenuItem value={"Adv."}>Adv.</MenuItem>
          </Select>
          <Input
            className={classes.input}
            style={{ width: "70%" }}
            name="firstName"
            onChange={handleChange}
            value={form.firstName ? form.firstName : ""}
          />
          </Box>
        </Box>
        <Box className={classes.inputBox}>
          <Box className={classes.inputLabel}>Last Name</Box>
          <Input
            className={classes.input}
            name="lastName"
            onChange={handleChange}
            value={form.lastName ? form.lastName : ""}
          />
        </Box>
        <Box className={classes.inputBox}>
          <Box className={classes.inputLabel}>Qualification</Box>
          <Input
            className={classes.input}
            name="qualification"
            onChange={handleChange}
            value={form.qualification ? form.qualification : ""}
          />
        </Box>
        <Box className={classes.inputBox}>
          <Box className={classes.inputLabel}>Mobile *</Box>
          <Box display='flex' alignItems="center">

          <Input
            className={classes.input}
            value="+91"
            disabled
            style={{ width: "15%", marginRight: "2%" }}
          />
          <Input
            className={classes.input}
            name="mobile"
            onChange={handleChange}
            style={{ width: "83%" }}
            value={form.mobile ? form.mobile : ""}
          />
          </Box>
        </Box>
        <Box className={classes.inputBox}>
          <Box className={classes.inputLabel}>Alternate mobile (if any)</Box>
          <Box display='flex' alignItems="center">

          <Input
            className={classes.input}
            value="+91"
            disabled
            style={{ width: "15%", marginRight: "2%" }}
          />
          <Input
            className={classes.input}
            name="alternateMobile"
            onChange={handleChange}
            style={{ width: "83%" }}
            value={form.alternateMobile ? form.alternateMobile : ""}
          />
          </Box>
        </Box>
        <Box className={classes.inputBox}>
          <Box className={classes.inputLabel}>Email Id *</Box>
          <Input
            className={classes.input}
            name="emailId"
            onChange={handleChange}
            value={form.emailId ? form.emailId : ""}
          />
        </Box>

        <Box className={classes.inputBox}>
          <Box className={classes.inputLabel}>Office address *</Box>
          <Input
            multiline
            minRows={2}
            className={classes.input}
            name="officeAddress"
            onChange={handleChange}
            value={form.officeAddress ? form.officeAddress : ""}
          />
        </Box>

        <Box className={classes.inputBox}>
          <Box className={classes.inputLabel}>Pincode *</Box>
          <Input
            className={classes.input}
            name="pincode"
            onChange={handleChange}
            value={form.pincode ? form.pincode : ""}
          />
        </Box>

        <Box className={classes.inputBox}>
          <Box className={classes.inputLabel}>Alternate Office Address</Box>
          <Input
            multiline
            minRows={2}
            className={classes.input}
            name="alternateOfficeAddress"
            onChange={handleChange}
            value={form.alternateOfficeAddress ? form.alternateOfficeAddress : ""}
          />
        </Box>

        <Box className={classes.inputBox}>
          <Box className={classes.locationLinkInfoFlex}>
            {renderModal()}

            <Box className={classes.inputLabel}>Location link (Optional) </Box>
            <IconButton
              onClick={() => setOpenModal(true)}
              style={{ padding: 0 }}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </Box>
          <Input
            className={classes.input}
            name="locationLink"
            onChange={handleChange}
            value={form.locationLink ? form.locationLink : ""}
          />
        </Box>
        <Box className={classes.inputBox}>
          <Box className={classes.inputLabel}>Website link (Optional) </Box>
          <Input
            className={classes.input}
            name="websiteLink"
            onChange={handleChange}
            value={form.websiteLink ? form.websiteLink : ""}
          />
        </Box>
        {error != null && <Box className={classes.error}>{error}</Box>}
        <Button
          disabled={loading}
          className={classes.createCardBtn}
          onClick={handleOnSubmit}
        >
          {type == CREATE_NEW_VISITING_CARD ? "Create card" : "Update Card"}
        </Button>
      </Container>
    </>
  );
}

export default CreateCard;
