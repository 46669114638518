import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        fontFamily: "Inter",
        lineHeight: 1.3
    },
    header: {
        padding: 20,
        paddingTop: 100,
        paddingBottom: 100,
        minHeight: '100vh',
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 600,
        textAlign: 'center',
        marginBottom: 20
    },
    subHeading: {
        fontWeight: 600,
        marginTop: 20,
        marginBottom: 20
    },
    bold: {
        fontWeight: 500
    }
}))