import React, { useEffect, useState } from "react";
import { useStyles } from "./CreateVisitingCard/CardsStyle";
import { VisitingCardStyles } from "./VisitingCard/VisitingCardStyles";
import {
  Box,
  Container,
  Fade,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@mui/base";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Divider from "@mui/material/Divider";
import CircleIcon from "@mui/icons-material/Circle";
import LinkIcon from "@mui/icons-material/Link";
import { getAllCards } from "../../api/VisitingCard";
import CloseIcon from "@mui/icons-material/Close";
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import {
  getCardLink,
  handleOpenLink,
  handleSystemLinkOpen,
  isPresent,
} from "../../utils/helper";
import Backdrop from "@mui/material/Backdrop";
import Header from "../Homepage/Header/Header";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../redux/actions/auth";
import SmsIcon from "@mui/icons-material/Sms";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WhatsappIconDownload from '../../assets/whatsapp.svg'
import gmailIcon from '../../assets/Gmail_icon.svg'
import { VISITING_CARD } from "../../redux/const/types";

function Cards() {
  const classes = useStyles();
  const visitingCardClasses = VisitingCardStyles()

  const history = useHistory();
  const dispatch = useDispatch();

  const [cards, setCards] = useState([]);
  const [openSelectCardModal, setOpenSelectCardModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});

  const [totalViews, setTotalViews] = useState()
  const [totalActiveCard, setTotalActiveCards] = useState()


  const handleCloseShareModal = () => {
    setShareModal(false);
  };

  const handleModalWhatsappShare = () => {
    if (selectedCard != {}) {
      const text = `Hey, Please find the DigiCard of ${
        selectedCard.companyName
      } here: ${
        getCardLink(selectedCard.uniqueId, selectedCard.type)
      }. It has all our contact info and services offered for easy access. Let me know if you have any questions!`;
      const encodedText = encodeURIComponent(text);
      handleOpenLink(`https://wa.me/?text=${encodedText}`);
    }
    handleCancelShare();
  };

  const handleSmsShare = () => {
    const card = selectedCard.type == VISITING_CARD ? "E-Visiting Card" : "DigiCard"
    const body = encodeURIComponent(
      `Hey, Please find the ${card} of ${selectedCard.companyName} here: ${getCardLink(selectedCard.uniqueId, selectedCard.type)}. It has all our contact info and services offered for easy access. Let me know if you have any questions!`
    );
    handleSystemLinkOpen(`sms:?&body=${body}`);
  };

  const handleEmailShare = () => {
    const card = selectedCard.type == VISITING_CARD ? "E-Visiting Card" : "DigiCard"
    const subject = encodeURIComponent(`${card} of ${selectedCard.companyName}`);
    const body = encodeURIComponent(
      `Hey, Please find the ${card} of ${selectedCard.companyName} here: ${getCardLink(selectedCard.uniqueId, selectedCard.type)}. It has all our contact info and services offered for easy access. Let me know if you have any questions!`
    );
    handleSystemLinkOpen(`mailto:?subject=${subject}&body=${body}`);
  };


  const handleClose = () => {
    setOpenSelectCardModal(false);
    setSelectedCard({});
  };

  useEffect(() => {
    getAllCards()
      .then((res) => {
        setCards(res.data);
        
        let count = 0;
        let activeCards = 0;

        res.data.forEach(card => {
            count += card.visitCount
            const date = new Date().getTime();
            const validTill = new Date(card.validTill).getTime();
            if (date <= validTill) {
              activeCards+=1
            }
        });

        setTotalViews(count)
        setTotalActiveCards(activeCards)
      })
      .catch((err) => {dispatch(showSnackbar("Something went wrong", "error", 4000))})
  }, []);
  
  const handleCopy = (uniqueId, id, type) => {
    navigator.clipboard.writeText(getCardLink(uniqueId, type));
    var el = document.getElementById(`${id}cardLinkCopied`);

    el.style.display = "block";
    setTimeout(() => {
      el.style.display = "none";
    }, 2000);
  };

  const handleCardLinkCopy = (uniqueId, type, index) => {
    navigator.clipboard.writeText(getCardLink(selectedCard.uniqueId, selectedCard.type));
    var el = document.getElementById(`${index}shareVisitingCardLinkCopied`);
    el.style.display = "block";
    setTimeout(() => {
      el.style.display = "none";
    }, 2000);
  };

  const handleCancelShare = () => {
    handleClose();
    setSelectedCard({});
  };

  const handleOpenShareModal = (card) => {
    setSelectedCard(card);
    setShareModal(true)
  };

  const navigationToEdit = (cardId, type) => {
    const path =
      type == "E-Visiting Card"
        ? "/card/visiting-card/update"
        : "/card/digital-card/update";
    history.push({
      pathname: `${path}/${cardId}`,
      state: { cardId: cardId },
    });
  };

  const renderModal = () => {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSelectCardModal}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openSelectCardModal}>
          <Box className={classes.modal}>
            <Box display='flex' justifyContent='space-between'>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
            >
              Select Card type
            </Typography>
            <IconButton onClick={() => setOpenSelectCardModal(false)} style={{padding: 0}}>
              <CloseIcon />
            </IconButton>
            </Box>

            <Box
              id="transition-modal-description"
              className={classes.modelButtonFlex}
            >
              <Button
                onClick={() => history.push("/card/digital-card/create")}
                className={classes.modelButton}
              >
                <Typography>DigiCard</Typography>
              </Button>
              <Button
                className={classes.modelButton}
                onClick={() => history.push("/card/visiting-card/create")}
              >
                <Typography>E-Visiting Card</Typography>
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  };

  const renderShareModal = (card, index) => {
    return (
      <Modal
        aria-labelledby="share-modal-title"
        aria-describedby="share-modal-description"
        open={shareModal}
        onClose={handleCloseShareModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={shareModal}>
          <Box className={visitingCardClasses.shareModal}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography
                id="share-modal-title"
                style={{
                  fontWeight: 500,
                  fontSize: "1.2rem",
                  marginBottom: 20,
                }}
              >
                Share
              </Typography>
              <IconButton
                onClick={() => setShareModal(false)}
                style={{ padding: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Box
              id="share-modal-description"
              className={visitingCardClasses.modelButtonFlex}
            >
              <Box className={classes.shareBtnFlex}>
                <IconButton
                  onClick={handleModalWhatsappShare}
                  className={visitingCardClasses.shareBtn}
                >
                  <img src={WhatsappIconDownload} width={"25"}/>
                  {/* <WhatsAppIcon /> */}
                </IconButton>
                <Typography className={visitingCardClasses.shareBtnText}>
                  Whatsapp
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => handleSmsShare(card.companyName)}
                  className={visitingCardClasses.shareBtn}
                >
                   <SmsIcon style={{color: '#2096f3'}} />
                </IconButton>
                <Typography className={visitingCardClasses.shareBtnText}>
                  SMS
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => handleEmailShare(card.companyName)}
                  className={visitingCardClasses.shareBtn}
                >
                  {/* <EmailIcon /> */}
                  <Box style={{width: 24, height: 24}}>
                    <img src={gmailIcon} style={{width: 24}} />
                  </Box>
                </IconButton>
                <Typography className={visitingCardClasses.shareBtnText}>
                  Email
                </Typography>
              </Box>
            </Box>
            <Box style={{ marginTop: 20 }}>
              <Typography className={visitingCardClasses.pageLink}>
                Page Link
            </Typography>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={() => handleCardLinkCopy(selectedCard.uniqueId, selectedCard.type, index)}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  className: visitingCardClasses.pageLinkCopy,
                }}
                fullWidth
                disabled
                defaultValue={getCardLink(selectedCard.uniqueId, selectedCard.type)}
              ></TextField>

              <Typography
                id={`${index}shareVisitingCardLinkCopied`}
                className={visitingCardClasses.linkCopiedTxt}
              >
                Link Copied
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  };

  return (
    <>
      <Header callback={() => history.push("/")} text={"Your Cards"} isBlue={true}/>
      <Container className={classes.root}>
        {renderModal()}
        {cards && cards.length > 0 ? (
          <>
          {cards.map((card, index) => (
            <Paper key={card.id} className={classes.card}>
              {renderShareModal(card, index)}
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.cardTitle}>{card.companyName}</Box>
                <Box position="relative">
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => handleCopy(card.uniqueId, index, card.type)}
                  >
                    <LinkIcon style={{ marginRight: 10 }} />
                  </IconButton>
                  <Box
                    id={index + "cardLinkCopied"}
                    className={classes.linkCopiedTxt}
                  >
                    Link Copied
                  </Box>
                </Box>
              </Box>
              <Box className={classes.cardSubTitle}>
                {isPresent(card, card.firstName) && card.firstName}{" "}
                {isPresent(card, card.lastName) && card.lastName}
              </Box>
              <Box className={classes.cardType}>{card.type}</Box>
              <Box className={classes.flexIcons}>
                <Box className={classes.cardName}>
                  <VisibilityIcon
                    style={{ marginRight: 5, transform: "scale(0.8)" }}
                  />
                  {card.visitCount} views
                </Box>
                <Box className={classes.cardName}>
                  <CircleIcon
                    style={{ marginLeft: 3, transform: "scale(0.4)" }}
                  />
                  Valid till: {card.validTill}
                </Box>
              </Box>
              <Divider className={classes.divider} />
              <Box className={classes.flexActions}>
                <Button
                  onClick={() =>
                    window.open(getCardLink(card.uniqueId, card.type))
                  }
                  className={classes.actionButtonBorder}
                >
                  <Typography>Preview</Typography>
                </Button>
                <Button
                  onClick={() => navigationToEdit(card.id, card.type)}
                  className={classes.actionButtonBorder}
                >
                  <Typography>Edit</Typography>
                </Button>
                <Button
                  onClick={() => handleOpenShareModal(card)}
                  className={classes.actionButton}
                >
                  <Typography>Share</Typography>
                </Button>
              </Box>
            </Paper>
          ))}
            <Typography className={classes.overallInsightsHeading}>Your Overall Insights</Typography>
            <Box className={classes.overallInsights}>
              <Box className={classes.overallInsightBox} style={{marginRight: '2%'}}>
              <Box className={classes.insightIcon}><VisibilityIcon/></Box>
              <Box className={classes.insightIconSubheading}>{totalViews}</Box>
              <Box className={classes.insightIconDesc}>Total Views</Box>
              </Box>
              <Box className={classes.overallInsightBox} style={{marginRight: '2%'}}>
              <Box className={classes.insightIcon}><PhonelinkIcon/></Box>
              <Box className={classes.insightIconSubheading}>{totalActiveCard}</Box>
              <Box className={classes.insightIconDesc}>Total Active Cards</Box>
              </Box>
            </Box>
          </>
        ) : (
          <Typography>Your created cards will show here.</Typography>
        )}
        
        <Button
          className={classes.createCardBtn}
          onClick={() => setOpenSelectCardModal(true)}
        >
          <Typography fontSize={'1rem'}>Create new card</Typography>
        </Button>
        <Box className={classes.renewalMessage}>For renewal and any queries, please contact us at <Link href="mailto:support@synclink.in">support@synclink.in</Link> or chat with us on <Link href="https://wa.me/919257430479">+91-9257430479</Link></Box>
      </Container>
    </>
  );
}

export default Cards;
