import React, { useEffect, useRef, useState } from "react";
import Home from "./Components/Home";
import ReactPageScroller from "react-page-scroller";
import Annoucements from "./Components/Annoucements";
import Payment from "./Components/Payment";
import { useStyles } from "./Components/Style";
import { Box, IconButton, Typography } from "@mui/material";
import ServicesOfferedV2 from "./Components/ServicesOffered";
import AboutUs from "./Components/AboutUs";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getDigitalCardView } from "../../../api/DigitalCard";
import { makeVCard } from "../../../utils/generateVcard";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../redux/actions/auth";

import Footer from "./Components/Footer";
import Gallery from "./Components/Gallery";

function DigitalCard() {
  const classes = useStyles();
  const { uniqueId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const InitState = {
    prefix: "CA",
    firstName: "",
    lastName: "",
    qualification: "",
    mobile: "",
    alternateMobile: "",
    emailId: "",

    companyName: "",
    companyLogo: "",
    uploadedCompanyLogo: "",
    aboutCompany: "",
    officeAddress: "",
    city: "",
    pincode: "",
    locationLink: "",
    upiId: "",
    facebookLink: "",
    twitterLink: "",
    youtubeLink: "",
    linkedinLink: "",
    websiteLink: "",

    testimonialDescription: "",
    testimonialAuthor: "",
    testimonialAuthorOccupation: "",

    specialities: [],

    serviceOfferedTitle: "",
    serviceOfferedDesc: "",
    servicesOffered: [],

    announcementTitle: "",
    announcementLink: "",
    announcements: [],
  };

  const [cardDetail, setCardDetail] = useState({});
  const [cardExpired, setCardExpired] = useState(false);
  const [isCardOwner, setIsCardOwner] = useState(false);
  const [paymentDetailsExists, setPaymentDetailsExists] = useState(true);
  const [galleryViewExists, setGalleryViewExists] = useState(true)

  useEffect(() => {
    getDigitalCardView(uniqueId)
      .then((res) => {
        const cardDetail = res.data.card;

        console.log("cardDetail", cardDetail)

        setCardDetail(cardDetail);
        setIsCardOwner(res.data.isOwner);
        const date = new Date().getTime();
        const validTill = new Date(res.data.card.validTill).getTime();
        if (date > validTill) {
          setCardExpired(true);
        }

        if (cardDetail.galleryImages.length == 0){
          setGalleryViewExists(false)
        }

        const check =
          cardDetail &&
          cardDetail.upiId === "" &&
          cardDetail.facebookLink === "" &&
          cardDetail.twitterLink === "" &&
          cardDetail.youtubeLink === "" &&
          cardDetail.linkedinLink === "" &&
          cardDetail.testimonialAuthor === "" &&
          cardDetail.testimonialDescription === "";
        setPaymentDetailsExists(!check);
      })
      .catch((err) => {
        history.push("/");
      });
  }, [uniqueId]);

  const handleContactSave = () => {
    const name = `${cardDetail.firstName} ${cardDetail.lastName}`;
    makeVCard(
      name,
      cardDetail.companyName,
      cardDetail.mobile,
      cardDetail.emailId
    );
    dispatch(
      showSnackbar(
        "Please click on the downloaded file to save contact details",
        "success"
      )
    );
  };



  return (
    <>
      {cardExpired && (
        <Box className={classes.expiredTextBox}>
          <Box className={classes.expiredText}>
            Your card is expired, please contact us at support@synclink.in
          </Box>
        </Box>
      )}
      <Box
        style={
          cardExpired
            ? { filter: "blur(7px)", overflowY: "hidden" }
            : { scroll: "smooth" }
        }
      >
        <Home
          id="home"
          isCardOwner={isCardOwner}
          cardDetail={cardDetail}
          handleContactSave={handleContactSave}
        />
        <AboutUs
          id="aboutUs"
          cardDetail={cardDetail}
        />
        <ServicesOfferedV2
          id="services"
          cardDetail={cardDetail}
        />
        <Annoucements
          id="announcement"
          cardDetail={cardDetail}
        />
        {galleryViewExists && (
          <Gallery
            id="gallery"
            cardDetail={cardDetail}
            />
        )}
        {paymentDetailsExists && (
          <Payment
            id="payment"
            cardDetail={cardDetail}
          />
        )}

        <Footer paymentDetailsExists = {paymentDetailsExists} galleryViewExists={galleryViewExists} />
        
      </Box>
    </>
  );
}

export default DigitalCard;
