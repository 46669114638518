import { makeStyles } from "@mui/styles";

export const digitalCardStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    padding: 30,
    fontFamily: "Inter",
    backgroundColor: theme.home.secondary.background,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 110
  },
  heading: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: "700",
    margin: 10,
  },
  desc: {
    margin: 10,
    marginBottom: 20,
    marginTop: 0,
  },
  accordionTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
  },
  inputBox: {
    marginBottom: 12,
    // width: "95%",
  },
  inputLabel: {
    color: "#535d6e",
    fontWeight: "500",
    marginBottom: 10,
    // letterSpacing: 1,
    fontSize: theme.typography.pxToRem(16),
  },
  subInputLabel: {
    color: "#535d6e",
    fontWeight: "500",
    margin: 10,
    marginTop: 20,
    marginLeft: 0,
    fontSize: theme.typography.pxToRem(16),
  },
  input: {
    width: "100%",
    padding: "5px 10px",
    backgroundColor: theme.palette.primary.white,
    borderRadius: 2,
    "&.Mui-focused": {
      borderBottom: "1px solid #0E4160 !important",
    },
    "&.MuiInput-root::after": {
      borderBottom: "1px solid #0E4160 !important",
    },
  },
  selectInput: {
    width: "100%",
    backgroundColor: theme.palette.primary.white,
    outline: 'none',
    padding: 0,
    height: 43,
    '&.MuiSelect-select':{
      padding: 0,
       borderTopRightRadius: '0',
      borderBottomRightRadius: '0'
    },
    '& fieldset': {
      border: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      padding: 2,
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
      
    } 
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  serviceBox: {
    marginBottom: 10,
  },
  serviceTitle: {
    fontWeight: 600,
  },
  serviceDesc: {
    fontWeight: 500,
    lineHeight: 1.3
  },
  stepBox: {
    margin: 10,
    marginTop: 20,
  },
  stepButton: {
    border: "1px solid",
    color: theme.home.primary.figmaBlue,
    marginTop: 15,
  },
  error: {
    color: "red",
    textAlign: "center",
  },
  imagePreviewBox: {
    display: 'flex',
    alignItems: 'center'
  },
  imagePreview: {
    maxWidth: 150,
    maxHeight: 150,
    margin: 10
  },

  galleryImagePreview:{
    maxWidth: '100%',
    maxHeight: 150,
    margin: 5
  },

  galleryImageGrid: {
    position: 'relative'
  },

  galleryImageCloseButton: {
    position: 'absolute',
    top: 0,
    left: 0
  },

  // Location info modal
  locationLinkInfoFlex: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    position: 'relative'
  },
  locationModal: {
    height: '100vh',
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center'
  },
  locationModalContent: {
    background: 'white',
    width: '80%',
    borderRadius: 10,
    boxShadow: 2,
    padding: 10
  },


  // Bottom fixed footer
  footer: {
    position: 'fixed',
    bottom: 0,
    background: theme.home.primary.figmaBlue,
    width: '100%',
    height: 70,
    padding:10,
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflowX: 'auto',
    overflowY: 'hidden'
  },
  footerIconFlex: {
    display: 'flex',
    flexDirection: 'column'
  },
  footerIcon: {
    color: 'white'
  },
  footerIconText: {
    color: 'white',
    fontSize: theme.typography.pxToRem(12),
  },

  stack: {
    '-ms-overflow-style': 'none',  /* Internet Explorer 10+ */
    'scrollbar-width': 'none',
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  '.stack::-webkit-scrollbar':{
    display: 'none'
  }
}));
