import { Box, Container, Paper } from '@mui/material'
import React from 'react'
import {useStyles} from './PrivacyPolicyStyles'
import Header from '../Header/Header';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Footer from '../Footer/Footer';

function PrivacyPolicy() {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box className={classes.container}>
      <Header callback={() => history.push("/")} text={"Privacy Policy"} isBlue={true}/>
      <Box className={classes.header}>
        <Box className={classes.heading}>Privacy Policy</Box>
        <Box>Synclink is committed to protecting the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard the information you provide to us when using our digital business card creation website and making online payments.</Box>
        <Box className={classes.subHeading}>Information We Collect:</Box>
        <Box><span className={classes.bold}>Personal Information:</span> When you use our website or make a purchase, we may collect personal information such as your name, email address, billing address, phone number, and payment details.</Box>
        <Box><span className={classes.bold}>Usage Data:</span> We may collect information about how you interact with our website, including your IP address, browser type and referring website.</Box>
        <Box className={classes.subHeading}>How We Use Your Information:</Box>
        <Box><span className={classes.bold}>To Provide Services:</span> We use your personal information to create and customize your digital business card and to process your online payments.</Box>
        <Box><span className={classes.bold}>Communication:</span> We may use your contact information to send you transactional emails, updates, and marketing communications related to our services. You can opt-out of marketing communications at any time.</Box>
        <Box><span className={classes.bold}>Payment Processing:</span> Your payment information is securely processed by our trusted payment gateway providers.</Box>
        <Box><span className={classes.bold}>Improvement of Services:</span> We analyze usage data to improve our website, services, and user experience.</Box>
        <Box className={classes.subHeading}>Information Sharing:</Box>
        <Box><span className={classes.bold}>Legal Compliance:</span> We may disclose your information in response to a subpoena, court order, or other governmental request, or when we believe it is necessary to comply with applicable laws, regulations, or legal processes.</Box>
        <Box className={classes.subHeading}>Data Security:</Box>
        <Box>We implement appropriate technical and organizational measures to safeguard your personal information against unauthorized access, disclosure, alteration, or destruction.</Box>
        <Box className={classes.subHeading}>Changes to this Privacy Policy:</Box>
        <Box>We reserve the right to update or change this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy on our website.</Box>
        <Box>By using our website and services, you consent to the collection and use of your information as described in this Privacy Policy. If you have any questions or concerns about this policy, please contact us at support@synclink.in.</Box>
        <Box className={classes.subHeading}>Last updated on: 15th March 2024</Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default PrivacyPolicy