import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Alert, Box, Snackbar } from "@mui/material";
import { hideSnackbar } from "./redux/actions/auth";
import { CREATE_NEW, CREATE_NEW_VISITING_CARD, UPDATE_EXISITING_VISITING_CARD, UPDATE_EXISTING } from "./redux/const/types";
import "./App.css";
import DigitalCard from "./pages/Cards/DigitalCard/DigitalCard";
import VisitingCard from "./pages/Cards/VisitingCard/VisitingCard";
import HomePage from "./pages/Homepage/HomePage";
import LoginPage from "./pages/Login/LoginPage";
import AccountActivate from "./pages/Login/AccountActivate";
import ProtectedRoute from "./utils/ProtectedRoute";
import Cards from "./pages/Cards/Cards";
import CreateCard from "./pages/Cards/CreateVisitingCard/CreateVisitingCard";
import CreateDigitalCard from "./pages/Cards/CreateDigitalCard/CreateDigitalCard";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ResetPassword from "./pages/Login/ResetPassword";
import PrivacyPolicy from "./pages/Homepage/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./pages/Homepage/PrivacyPolicy/Terms&Conditions";

function App({snackbar}) {
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideSnackbar())
  };

  return (
    <Router>
       <Snackbar
        open={snackbar.isOpen}
        anchorOrigin={{ vertical: snackbar.vertical, horizontal: snackbar.horizontal }}
        autoHideDuration={snackbar.autoHideDuration}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackbar.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/authentication/activate/:token">
          <AccountActivate />
        </Route>
        <Route path="/authentication/reset-password/:token">
          <ResetPassword />
        </Route>
        <Route exact path="/visiting-card/:uniqueId">
          <VisitingCard />
        </Route>
        <Route exact path="/card/visiting-card/create">
          <ProtectedRoute component={<CreateCard type={CREATE_NEW_VISITING_CARD} />} />
        </Route>
        <Route exact path="/card/digital-card/create">
          <ProtectedRoute component={<CreateDigitalCard type={CREATE_NEW}/>} />
        </Route>
        <Route exact path="/card/visiting-card/update/:cardId">
          <ProtectedRoute component={<CreateCard type={UPDATE_EXISITING_VISITING_CARD} />} />
        </Route>
        <Route exact path="/card/digital-card/update/:cardId">
          <ProtectedRoute component={<CreateDigitalCard type={UPDATE_EXISTING}/>} />
        </Route>
        <Route exact path="/cards">
          <ProtectedRoute component={<Cards />} />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/terms-and-conditions">
          <TermsConditions />
        </Route>
        <Route exact path="/:uniqueId">
          <DigitalCard />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => ({ snackbar: state.snackbar });

export default connect(mapStateToProps)(App);
