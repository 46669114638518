import React, { useState } from "react";
import { useStyles } from "./Style";
import { Box, Container, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function Gallery({ id, cardDetail }) {
  const classes = useStyles();

  const [selectedImage, setSelectedImage] = useState("")

  const handleOpenImage = (src) => {
    setSelectedImage(src)
  }

  const handleCloseImage = () => {
    setSelectedImage("")
  }

  return (
    <Container maxWidth={"sm"} className={classes.root}>
      <Box id={id} className={classes.topDrop}>
        <Box className={classes.topDropText}>Gallery</Box>
      </Box>
      <Box className={classes.galleryGrid}>
        {cardDetail.galleryImages &&
          cardDetail.galleryImages.length > 0 &&
          cardDetail.galleryImages.map((img, index) => (
              <Box key={index} className={classes.galleryImageGrid}>
                <img
                  key={index}
                  src={img}
                  className={classes.galleryImagePreview}
                  onClick={() => handleOpenImage(img)}
                />
              </Box>
          ))}
      </Box>
      {selectedImage != "" && <Box className={classes.popupImageBox}>
          <IconButton onClick={handleCloseImage} className={classes.popupImageCloseIcon}><CloseIcon style={{color: 'white'}}/></IconButton>
          <img className={classes.popupImage} src={selectedImage} />
      </Box>
      }
    </Container>
  );
}

export default Gallery;
