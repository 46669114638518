import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    header: {
        padding: 20,
        color: theme.home.primary.background,
        display: 'flex',
        alignItems: 'center',
        paddingTop: 20,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 5
    },
    backIcon :{
        color: theme.home.primary.background,
    },
    text: {
       fontSize: theme.typography.pxToRem(20),
       fontWeight: 500,
       marginLeft: 20
    }
}))